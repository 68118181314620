import React from "react";
import { useOidc } from "@axa-fr/react-oidc";
import { Navigate } from "react-router-dom";

import background from "../../images/loginimage.png";
import { StyleVariables } from "../../styles/variables";
import { Button } from "../ui/components";

function Login() {
  const { login, isAuthenticated } = useOidc();

  return isAuthenticated ? (
    <Navigate to='/' replace />
  ) : (
    <div
      style={{
        position: "relative",
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          zIndex: 1,
          padding: "3em",
          borderRadius: "0.25em",
          backgroundColor: "rgba(36, 39, 48, 0.8)",
        }}
      >
        <h1
          style={{
            position: "relative",
            top: 0,
            fontSize: "55px",
            color: StyleVariables.primaryColor,
          }}
        >
          {" "}
          Precinct{" "}
        </h1>

        <Button
            rtype='bg1'
            width="50%"
            htmlType='submit'
            onClick={async () => {
              login();
            }}
        >
          LOGIN
        </Button>
        
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          backgroundImage: `url(${background})`,
          height: "100%",
          width: "100%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          filter: "blur(2px) brightness(0.5)",
        }}
      ></div>
    </div>
  );
}
export default Login;
