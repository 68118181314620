import axios from "axios";
import { CREATE_INCIDENT, GET_INCIDENTS, REPORT_INCIDENT } from "../constants";
import { getAccessToken } from "../constants";

export interface CreateIncidentPayload extends Omit<Incident, '_id'> { }

export type CreateSimpleIncidentPayload = Omit<Incident, '_id'>
export type CreateCustomIncidentPayload = Omit<Incident, '_id'> & {
    properties: IncidentDefaultProps & IncidentCustomProps
}

export type IncidentDefaultProps = {
    node: number,
    event: string,
    title: string,
}
type IncidentCustomProps = {
    description: string,
    incident_severity: string,
    incident_priority: string,
    human_losses: string,
    scale_of_impacts: string,
    ci_service_affected: string,
    expected_external_impacts_impac: string,
    report_recipients: string[],
    hazard_type: string,
    ci_business_name: string,
    ci_sector: string
    geometry?: any
}
export interface Incident {
    _id: string;
    type: 'Feature';
    report: boolean;
    properties: IncidentDefaultProps
}
export interface GetIncident {
    _id: string;
    type: 'Feature';
    geometry: any;
    properties: {
        node: number,
        event: string,
        ci_business_name: string,
        hazard_type: string,
        title: string,
        description: string,
        severity: number,
        creation_date_time_utc: string
    }
}

export interface IncidentListRecord {
    _id: string
    key: number
    event: string;
    title: string;
    description: string,
    creationDate: string,
    node: string
}

export function getIncidentsApi(params = {}): Promise<GetIncident[]> {
    const token = getAccessToken()
    const headers = { "Authorization": `Bearer ${token}` }

    return new Promise((resolve, reject) => {
        axios
            .get(GET_INCIDENTS
                , { params, headers }
            )
            .then(function (response) {
                resolve(response!.data);
            })
            .catch(function (error) {
                reject(error);
            })
            .finally(function () { });
    });
}

export function deleteIncidentsApi(params = {}): Promise<GetIncident[]> {
    const token = getAccessToken()
    const headers = { "Authorization": `Bearer ${token}` }

    return new Promise((resolve, reject) => {
        axios
            .delete(GET_INCIDENTS
                , { data: params, headers }
            )
            .then(function (response) {
                resolve(response!.data);
            })
            .catch(function (error) {
                reject(error);
            })
            .finally(function () { });
    });
}

export function createIncident(createSimPayload: CreateIncidentPayload): Promise<Incident[]> {
    const token = getAccessToken()
    const headers = { "Authorization": `Bearer ${token}` }

    return new Promise((resolve, reject) => {
        axios
            .post(CREATE_INCIDENT, createSimPayload, { headers })
            .then(function (response) {
                resolve(response!.data);
            })
            .catch(function (error) {
                reject(error);
            })
            .finally(function () { });
    });
}


export function reportIncidentById(id: string, createSimPayload: CreateIncidentPayload): Promise<Incident[]> {
    const token = getAccessToken()
    const headers = { "Authorization": `Bearer ${token}` }

    return new Promise((resolve, reject) => {
        axios
            .post(REPORT_INCIDENT + "/" + id, createSimPayload, { headers })
            .then(function (response) {
                resolve(response!.data);
            })
            .catch(function (error) {
                reject(error);
            })
            .finally(function () { });
    });
}
