import styled from "styled-components";
import { StyleVariables } from "../../styles/variables";

export const MenuContainer = styled.div`
  padding: 10px;
  background-color: ${StyleVariables.primaryBg};
  display: flex;
`;

export const MenuItem = styled.div<{ active?: boolean }>`
  color: ${StyleVariables.primaryColor};
  border: 1px solid
    ${(props) =>
    props.active ? '#8c8686' : StyleVariables.borderColor};
  background-color: ${StyleVariables.secondaryBg};
  min-width: 120px;
  padding: 8px;
  margin-right: 5px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  :hover {
    background-color: ${StyleVariables.primaryBg};
  }
`;

export const styleSideBarButton: any = {
  position: "absolute",
  top: "20px",
  left: "20px",
  zIndex: 3000,
  width: "25px",
  height: "25px",
  background: StyleVariables.primaryBg,
  color: "white",
};

export const styleTabsButton: any = {
  // position: "absolute",
  // bottom: "30px",
  // right: "25px",
  // zIndex: 100,
};
export const styleShowHideTabButton: any = {
  position: "absolute",
  width: "20px",
  height: "20px",
  zIndex: 101,
  right: "9px",
  top: "97px",
};

export const styleTimeline: any = {
  position: "absolute",
  bottom: "20px",
  left: "50%",
  transform: "translateX(-50%)",
  zIndex: 2000,
  width: "920px",
  background: "#242730",
  color: "white",
};