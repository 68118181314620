
//TODO: Config Vis State from kepler type
function getDatasetConfig(layers: any[]): {
  version: string;
  config: any;
} {
  return {
    version: "v1",
    config: {
      visState: {
        filters: [],
        layers,
        interactionConfig: {
          tooltip: {
            fieldsToShow: {
              // "heatmap-attiki-lines": ["name", "code", "address", "entries", "exits"],
              // "sf-zip-geo": ["OBJECTID", "ZIP_CODE", "ID", "name", "STREETNAME"],
            },
            enabled: true,
          },
          brush: {
            size: 0.5,
            enabled: false,
          },
          geocoder: {
            enabled: false,
          },
        },
        layerBlending: "normal",
      },
      uiState: {
        activeSidePanel: null,
      },
      mapStyle: {
        isSplit: true,
        //   styleType: "b9tnac",
        //   mapStyles: {
        //     b9tnac: {
        //       accessToken: null,
        //       custom: true,
        //       icon: "https://api.mapbox.com/styles/v1/heshan0131/cjg0ks54x300a2squ8fr9vhvq/static/-122.3391,37.7922,9,0,0/400x300?access_token=pk.eyJ1IjoidWJlcmRhdGEiLCJhIjoiY2pmc3hhd21uMzE3azJxczJhOWc4czBpYyJ9.HiDptGv2C0Bkcv_TGr_kJw&logo=false&attribution=false",
        //       id: "b9tnac",
        //       label: "label maker",
        //       url: "mapbox://styles/heshan0131/cjg0ks54x300a2squ8fr9vhvq",
        //     },
        //   },
      },
    },
  };
}

export default getDatasetConfig;
