import axios from "axios";
import { REAL_TIME_API } from "../constants";


export function getRealTimeApi(): Promise<any> {
  return new Promise((resolve, reject) => {
    axios
      .get(REAL_TIME_API)
      .then(async function (response) {
        resolve(response!.data);
      })
      .catch(function (error) {
        reject(error);
      })
      .finally(function () { });
  });
}

export const realTimeInterval = (pollingTimeinMs: number, cb: () => void) =>
  setInterval(cb, pollingTimeinMs);
