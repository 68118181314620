import React, { useState } from "react";
import { StyleVariables } from "../../styles/variables";
import { Button } from "../ui/components";
import { useDispatch } from "react-redux";
import { setFilteringGeoJsonLayers } from "../../redux/appSlice";
import { Col, Row } from "antd";
import { TagComp } from "../tag/tag";

const styleSideBar: any = {
  position: "absolute",
  top: "20px",
  left: "5%",
  zIndex: 1000,
  width: "300px",
  height: "360px",
  background: StyleVariables.primaryBg,
  color: "white",
  padding: "20px"
};

const styleSideBarTitle: any = {
  marginTop: "10px",
  textAlign: "center",
  fontSize: "17px",
  fontWeight: "400",
};
const opLevelValues = [
  "Fully operational",
  "Functional",
  "Limited",
  "Critical",
  "Non operational",
];
const typeValues = ["CI Service", "CI Asset"];
const operatorValues = [
  "AIA",
  "AMETRO",
  "ATTD",
  "Hospitals",
  "Power Company",
  "Bus Company",
];

type Props = {};

export const SideBarFiltering: React.FC<Props> = React.memo(({ }) => {

  const [opLevel, setOpLevel] = useState<string[]>([]);
  const [type, setType] = useState<string[]>([]);
  const [operator, setOperator] = useState<string[]>([]);

  const dispatch = useDispatch();

  const filterNodes = () => {
    dispatch(
      setFilteringGeoJsonLayers({ type, opLevel, operator })
    );
  };
  const resetFiltering = () => {
    setOpLevel([]);
    setType([]);
    setOperator([]);

    dispatch(
      setFilteringGeoJsonLayers({
        type: [],
        opLevel: [],
        operator: [],
      })
    );
  };


  return (
    <div style={styleSideBar}>
      <div style={styleSideBarTitle}>
        <p>Filter nodes</p>
      </div>
      {inputDiv("Op. Level", opLevel, setOpLevel, opLevelValues)}
      {inputDiv("Type", type, setType, typeValues)}
      {inputDiv("Operator", operator, setOperator, operatorValues)}

      <Row gutter={16} style={{ marginTop: "20px" }}>
        <Col span={12} style={{}}>
          <Button
              rtype='primary'
              height='100%'
              onClick={filterNodes}
            >
              Filter Nodes
            </Button>
        </Col>
        <Col span={12}>
          <Button rtype='primary' height='100%' onClick={resetFiltering}>
            Reset
          </Button>
        </Col>
      </Row>
    </div>
  );
});
const inputDiv = (
  labelName: string,
  valueProperty: any,
  setFunc: any,
  selectValues: string[]
) => {
  return (
    <Row gutter={16} style={{ marginBottom: "10px", height: "70px" }}>
      <Col span={24} style={{ marginTop: "5px" }}>
        <label>{labelName}</label>
      </Col>
      <Col span={24} style={{ marginTop: "10px" }}>
        <TagComp
          prop={valueProperty}
          setProp={setFunc}
          possibleValues={selectValues}
        />
      </Col>
    </Row>
  );
};