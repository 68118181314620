import { DeleteOutlined } from "@ant-design/icons";
import { Tooltip, Popconfirm } from "antd";
import React, { useState } from "react";
import { ReactComponent as AddCircle } from "../../images/flaticon/add-circle.svg";
import { useDispatch } from "react-redux";
import {
  deleteExecutions,
  resetModal,
  setMitigationActions,
  toggleNewSimulationModal,
  resetSimulations,
  getExecutionsAction,
} from "../../redux/simulationsSlice";
import { setAppState } from "../../redux/appSlice";
import { removeViewIncidents } from "../../redux/incidentsSlice";



type FormProps = { executionSelectedRows:string[] };
export const SimulationHeaderTab: React.FC<FormProps> = ({
  executionSelectedRows,
}) => {
  const dispatch = useDispatch();

  const [confirmText, setText] = useState<string>("");

  const startSim = (): void => {
    dispatch(setMitigationActions([]));
    dispatch(setAppState("simulation"));
    dispatch(resetModal({ resetModal: false }));
    //@ts-ignore
    dispatch(resetSimulations());
    dispatch(toggleNewSimulationModal(true));
    dispatch(removeViewIncidents());
  };

  const deleteSimulations = () => {
    if (executionSelectedRows.length === 0) {
      dispatch(deleteExecutions({ all: true, ids: [] }));
    } else {
      dispatch(
        deleteExecutions({
          all: false,
          ids: executionSelectedRows.map((i: any) => i._id),
        })
      );

      setTimeout(() => {
        dispatch(
          getExecutionsAction({
            median_simulation: true,
            page: 1,
            pageSize: 5,
          })
      );
    }, 100);

    }
  };

  const deleteSimulationsText = () => {
    let text = "";
    if (executionSelectedRows.length === 0) {
      text = "Are you sure you want to delete all simulations?";
    } else {
      text = `Are you sure you want to delete ${executionSelectedRows.length} selected simulations?`;
    }
    setText(text);
  };

  return (
    <div className='card-title-container'>
      <span className='card-title'>Simulations</span>
      <div style={{ transform: "translateY(2px)", marginRight: "5px" }}>
        <Tooltip title='Create simulation'>
          <span className='add-anchor'>
            <a onClick={startSim}>
              <AddCircle className='add-circle' />
            </a>
          </span>
        </Tooltip>
        <span className='add-anchor'>
          <Popconfirm
            placement='topLeft'
            title={confirmText}
            onConfirm={deleteSimulations}
            okText='Yes'
            cancelText='No'
          >
            <a
              onClick={deleteSimulationsText}
              style={{
                color: "#bfbfbf",
                marginLeft: "10px",
                fontSize: "18px",
              }}
            >
              <DeleteOutlined style={{ transform: "translateY(-2px)" }} />
            </a>
          </Popconfirm>
        </span>
      </div>
    </div>
  );
};