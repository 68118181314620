/* eslint-disable import/no-webpack-loader-syntax */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import "../../App/App.scss";

import {
  Avatar,
  message,
  Menu as MenuAntd,
  Dropdown,
} from "antd";
import Draggable from "react-draggable";
import { GetIncident } from "../../api/incidents.api";
import useGetRealTimeData from "../../api/real-time-hook";
import { realTimeInterval } from "../../api/real-time-interval";
import { Simulation, Execution } from "../../api/simulations.api";
import { POLLING_TIMER_MS } from "../../constants";
import { AppStateType } from "../../models/app";
import {
  addExtraDataToMap,
  emptyDatasets,
  setAppState,
} from "../../redux/appSlice";
import { getLiveIncidentsAction } from "../../redux/incidentsSlice";
import {
  setRealTimeData,
  getRealTimeAction,
  getResilience,
} from "../../redux/realTimeDataSlice";
import {
  setResilience,
  getSimulation,
  toggleNewSimulationModal,
  resetModal,
} from "../../redux/simulationsSlice";
import { StoreState } from "../../redux/state";
import { prepareApiDataset } from "../../services/prepareApiDatasets";
import { openInfoNotification } from "../../utils/notifications";
import GlobalLoader from "../Loader/GlobalLoader";
import { Timeline, type TimelineLabel } from "../Timeline-control/Timeline";
import { MergedAppModals } from "../modals/MergedModals";
import { ExtraMap } from "../Map/Map";
import { CreateTableComp } from "../Menu/table";
import { useOidc, useOidcUser } from "@axa-fr/react-oidc";
import { UserOutlined } from "@ant-design/icons";
import { TabPanelModal } from "../tabPanel/tabPanel";
import { Button } from "../ui/components";
import {
  styleTimeline,
  styleSideBarButton,
  styleTabsButton,
} from "../Menu/menu-styled-components";
import { SideBarFiltering } from "../Map/filteringSideBar";
import { StyleVariables } from "../../styles/variables";

function Map(props: any): JSX.Element {
  const dispatch = useDispatch();

  const { logout } = useOidc();
  const { oidcUser, oidcUserLoadingState } = useOidcUser();

  const [currentMode, setCurrentMode] = useState<AppStateType>("real-time");
  const [polling, setPolling] = useState<NodeJS.Timeout | null>(null);

  const [showHideSideBar, setshowHideSideBar] = useState<boolean>(false);
  const [incidentCalled, setIncidentCalled] = useState<boolean>(false);

  const timelineRef = useRef<any>(null);

  const $selectedNode = useSelector<StoreState, number | null>(
    (state) => state.simulations.nodeId
  );

  const $activeAppState = useSelector<StoreState, AppStateType>(
    (state) => state.app.activeState
  );
  const $drawClicked = useSelector<StoreState, boolean>(
    (state) => state.app.drawClicked
  );
  const $liveIncidents = useSelector<StoreState, GetIncident[]>(
    (state) => state.incidents.liveIncidents
  );

  const $simulationsLoading = useSelector<StoreState, boolean>(
    (state) => state.simulations.loading
  );

  const $simulationsLoadingLabel = useSelector<StoreState, string>(
    (state) => state.simulations.loadingLabel
  );
  const $incidentsLoading = useSelector<StoreState, boolean>(
    (state) => state.incidents.loading
  );
  const $incidentsLoadingLabel = useSelector<StoreState, string>(
    (state) => state.incidents.loadingLabel
  );
  const $showTimeline = useSelector<StoreState, boolean>(
    (state) => state.simulations.timelineLabels.length > 0
  );
  const $activeTimelineLabel = useSelector<StoreState, string | null>(
    (state) => state.simulations.activeTimelineLabel
  );
  const $activeSimulation = useSelector<StoreState, Simulation | null>(
    (state) => state.simulations.activeSimulation
  );
  const $activeExecution = useSelector<StoreState, Execution | null>(
    (state) => state.simulations.activeExecution
  );
  const $mitigationActions = useSelector<StoreState, any[]>(
    (state: any) => state.simulations.mitigationActions
  );

  const [,realTimeData, ] = useGetRealTimeData();

  const dropdownProps: any = {
    placement: "bottomRight",
    overlay: (
      //@ts-ignore
      <MenuAntd>
        <MenuAntd.Item
          key='1'
          onClick={() => {
            logout("/", {});
          }}
        >
          Logout
        </MenuAntd.Item>
      </MenuAntd>
    ),
    trigger: ["click"],
    children: (
      <div onClick={(e) => e.preventDefault()}>
        <Avatar size='large' icon={<UserOutlined />} />
      </div>
    ),
  };

  // notificaitons code
  // useEffect(() => {
  //   if (!$liveIncidents) {
  //     return;
  //   }

  //   console.log("liveIncidents", $liveIncidents.length);
  //   $liveIncidents.forEach((incident) => {
  //     setTimeout(() => {
  //       openInfoNotification(
  //         "New incident",
  //         `${incident?.properties?.description || ""}`,
  //         incident
  //       );
  //     }, 5);
  //   });
  // }, [$liveIncidents]);

  useEffect(() => {
    if ($drawClicked) {
      setCurrentMode("incidents");
    }
  }, [$drawClicked]);

  useEffect(() => {
    console.log("activeAppStateactiveAppState", $activeAppState);
    setCurrentMode($activeAppState as AppStateType);
    if ($activeAppState === "executions") {
      emptyMapDataset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$activeAppState]);
  useEffect(() => {
    if ($activeSimulation) {
      setCurrentMode("simulation");
    }
  }, [$activeSimulation]);
  useEffect(() => {
    if (!$activeExecution) {
      return;
    }
    const medianSim: any = $activeExecution.simulations.find(
      (i) => i.medianSimulation
    );
    dispatch(setResilience($activeExecution.resilience));
    dispatch(getSimulation(medianSim._id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$activeExecution]);

  useEffect(() => {
    if ($selectedNode) {
      dispatch(toggleNewSimulationModal(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$selectedNode]);

  useEffect(() => {
    if ($selectedNode) {
      dispatch(toggleNewSimulationModal(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$selectedNode]);

  useEffect(() => {
    // TODO: Config must be given from backend
    if ($activeTimelineLabel) {
      const prop = $activeTimelineLabel;

      if (!$activeSimulation) return;
      const datasets = ($activeSimulation as Simulation).simulation_per_time[
        prop as string
      ];
      const res = prepareApiDataset(datasets);

      if (res.NonKeplerDatasets)
        dispatch(addExtraDataToMap(Object.values(res.NonKeplerDatasets)));
    }
    //@ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$activeTimelineLabel, $activeSimulation]);

  const realTimeDataParse = useCallback(
    (liveData: any) => {
      try {
        const res = prepareApiDataset(liveData);
        dispatch(
          setRealTimeData({ datasets: res.datasets, config: res.config })
        );
        if (Object.keys(res.NonKeplerDatasets).length > 0) {
          dispatch(addExtraDataToMap(Object.values(res.NonKeplerDatasets)));
        }
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch]
  );
  function emptyMapDataset() {
    props.dispatch(emptyDatasets());
  }
  function realTimeDispatches() {
    dispatch(getRealTimeAction());
    dispatch(getLiveIncidentsAction());
  }

  useEffect(() => {
    if (currentMode === "real-time") {
      setIncidentCalled(false);

      dispatch(getResilience());
      realTimeDispatches();
      setPolling(realTimeInterval(POLLING_TIMER_MS, realTimeDispatches));
    } else {
      clearInterval(polling as NodeJS.Timeout);
      setPolling(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMode]);

  useEffect(() => {
    const realTimeDataLength = Object.keys(realTimeData).length;
    if (realTimeDataLength === 0) return;
    realTimeDataParse(realTimeData);
  }, [realTimeData, realTimeDataParse]);


  const setLiveView = async () => {
    // dispatch(init_polling());
    const ref = timelineRef.current;
    if (ref) {
      ref.stopTimeline();
    }

    // needs dispatch instead of setting here to call use effect from redux
    dispatch(setAppState("real-time"));

    // await message.loading("Switching view to real time..", 2.5);
    message.success("Setting Live Data");
  };
  const emitedValue = (val: TimelineLabel) => { };
  
  return (
    <div>
      {($incidentsLoading as boolean) && (
        <GlobalLoader title={$incidentsLoadingLabel as string} />
      )}
      {($simulationsLoading as boolean) && (
        <GlobalLoader title={$simulationsLoadingLabel as string} />
      )}
      <MergedAppModals />
      <div
        style={{
          position: "absolute",
          top: "25px",
          right: "35px",
          zIndex: "200",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          flexDirection: "column",
          flexWrap: "nowrap",
        }}
      >
        <Dropdown {...dropdownProps} />
        <span style={{ color: StyleVariables.primaryColor }}>
          {oidcUser?.preferred_username}
        </span>
      </div>
      <Draggable>
        <div
          style={{
            position: "absolute",
            top: "15%",
            right: "1%",
            zIndex: 100,
          }}
        >
          <TabPanelModal />
        </div>
      </Draggable>

      {/* TODO: Fix this in styled components */}
      <div style={styleTimeline}>
        {
          currentMode === "simulation" && ($showTimeline as boolean) && (
            <Timeline
              // @ts-expect-error
              ref={timelineRef}
              emitValue={emitedValue}
              emitValueOnStart={true}
              playingDelayInMs={1000}
            />
          )
        }
      </div>

      <div style={styleSideBarButton}>
        <Button
          rtype='primary'
          height='100%'
          icon={showHideSideBar ? "left" : "right"}
          onClick={() => {
            setshowHideSideBar(!showHideSideBar);
          }}
        />
      </div>
      {showHideSideBar && <SideBarFiltering />}

      <div
        id='myDynamicTable'
        style={{
          color: "white",
          position: "absolute",
          left: "30px",
          bottom: "150px",
          zIndex: 10,
        }}
      >
        {$mitigationActions.length > 0 && $activeAppState === "executions" && (
          <CreateTableComp
            mitigationActions={$mitigationActions}
          ></CreateTableComp>
        )}
      </div>
      <ExtraMap />
    </div>
  );
  // }
}

const mapStateToProps = (state: any) => state;
const dispatchToProps = (dispatch: any) => ({ dispatch });

export default connect(mapStateToProps, dispatchToProps)(Map);
