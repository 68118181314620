import React, { useEffect } from "react";
import { INewTrigger } from "../../models/trigger";
import { Empty, Row, Col, Tooltip, Form } from "antd";
import styled from "styled-components";
import { StyleVariables } from "../../styles/variables";
import { FormComponentProps } from "antd/lib/form";

const TriggerListContainer = styled.div`
  background-color: ${StyleVariables.secondaryBg};
  border: 1px solid;
  border-color: ${StyleVariables.borderColor};
  padding: 10px;
  margin-bottom: 20px;
  svg {
    opacity: 0.2;
  }
`;

const ListItem = styled.div`
  background-color: ${StyleVariables.primaryBg};
  border: 1px solid transparent;
  padding: 5px;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  :hover {
    border: 1px solid red;
  }
`;

const ScrollableContent = styled.div`
  height: 150px;
  overflow: hidden;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: ${StyleVariables.scroll.trackColor};
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${StyleVariables.scroll.thumbColor};
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${StyleVariables.scroll.thumbColor};
  }
`;
interface props extends FormComponentProps {
  triggers: INewTrigger[];
  onRemoveTrigger: (trigger: INewTrigger) => void;
  onChange?: (data: any) => void;
};



const _TriggerList: React.FC<props> = ({
  form,
  triggers = [],
  onRemoveTrigger,
  onChange = () => {},
}) => {
  useEffect(() => {
    onChange({ triggers });
  }, [triggers]);

  
  return (
    <TriggerListContainer>
      {triggers.length === 0 ? (
        <Empty description={<span>No Triggers</span>} />
      ) : (
        <ScrollableContent>
          <Row gutter={16} style={{ color: StyleVariables.primaryColor }}>
            {triggers.map((trigger, index) => (
              <Col span={12} key={index}>
                <ListItem onClick={() => onRemoveTrigger(trigger)}>
                  <Tooltip title={trigger.nodeName}>
                    <strong>{trigger.nodeName}</strong>
                  </Tooltip>
                  <br />
                  <small>{trigger.trigger}</small>
                </ListItem>
              </Col>
            ))}
          </Row>
        </ScrollableContent>
      )}
    </TriggerListContainer>
  );
};

export const TriggerList = Form.create<props>({
  name: "trigger_list",
  onFieldsChange(props, changedFields) {},
  onValuesChange(props, values, allValues) {
    if ((props as any).onChange) {
      (props as any).onChange(allValues);
    }
  },
})(_TriggerList);