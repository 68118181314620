import { createSlice } from "@reduxjs/toolkit";
import { AppStateType } from "../models/app";
import { FeatureCollection } from "geojson";

// App reducer
export interface AppState {
    appName: string,
    loading: boolean;
    activeState: AppStateType;
    drawClicked: boolean;
    mapClicked: { val: boolean, time: string };
    geoJsonLayers: FeatureCollection[];
    emptyDatasets: string;
    filteringGeoJsonLayers: { opLevel:string[], operator:string[], type:string[]}
}

const initialState: AppState = {
    appName: "Precinct",
    drawClicked: false,
    mapClicked: { val: false, time: (new Date()).toUTCString() },
    loading: false,
    activeState: 'real-time',
    geoJsonLayers: [],
    emptyDatasets: "",
    filteringGeoJsonLayers: { opLevel: [], operator: [], type: [] }
};

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setAppState: (state, { payload }: { payload: AppStateType }) => {
            console.log('Set app state', payload)
            return { ...state, activeState: payload };
        },
        addExtraDataToMap: (state, { payload }: { payload: any }) => {
            console.log('payload', payload)
            return { ...state, geoJsonLayers: payload };
        },
        setFilteringGeoJsonLayers: (state, { payload }: { payload: any }) => {
            return {...state, filteringGeoJsonLayers: payload };
        },
        setMapClickedFalse: (state) => {
            return { ...state, mapClicked: { val: false, time: (new Date()).toUTCString() } };
        },
        emptyDatasets: (state) => {
            return { ...state, clearDatasets: (new Date()).toUTCString() };
        }
    },
    extraReducers: {
    }
});

// Action creators are generated for each case reducer function
export const { setAppState, addExtraDataToMap, setMapClickedFalse, emptyDatasets, setFilteringGeoJsonLayers } =
    appSlice.actions;

export default appSlice.reducer;
