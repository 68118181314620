import appReducer from "../appSlice";
import pollingReducer from "../pollingSlice";
import realTimeReducer from "../realTimeDataSlice";
import simualtionsReducer from "../simulationsSlice";
import incidentsReducer from "../incidentsSlice";

export const uiState = {
};

export const state = {
  uiState,
  mapState: {
    // isSplit: true,
    latitude: 37.96844738368003,
    longitude: 23.8437832891433,
    zoom: 12.110603383228844,
    bearing: -15.25,
    pitch: 56.012527487172655,
    dragRotate: true,
    isSplit: false,
  },
};

const reducers = {
  app: appReducer,
  polling: pollingReducer,
  realTime: realTimeReducer,
  simulations: simualtionsReducer,
  incidents: incidentsReducer
}
// export demoReducer to be combined in website app
export default reducers;