import { DeleteOutlined, FormOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Tooltip, Popconfirm, message } from "antd";
import React, { useState } from "react";
import { ReactComponent as AddCircle } from "../../images/flaticon/add-circle.svg";
import { useDispatch, useSelector } from "react-redux";
import { deleteIncidents, getIncidentsAction, toggleIncidentModal } from "../../redux/incidentsSlice";
import { setAppState } from "../../redux/appSlice";
import {
  addTriggerRedux,
  resetModal,
  toggleNewSimulationModal,
  resetSimulations,
} from "../../redux/simulationsSlice";
import { CreateCustomIncidentPayload, GetIncident, Incident, reportIncidentById } from "../../api/incidents.api";
import { _TRIGGERS } from "../../data/triggers/triggers";
import { Trigger } from "../../models/trigger";
import { StoreState } from "../../redux/state";

const nodes: Trigger[] = _TRIGGERS;

type FormProps = {
  incidentsSelectedRows: any;
  datasource: any;
  setDataSource: any;
  setRows: any;
  setRowKeys: any;
};
export const LiveViewHeaderTab: React.FC<FormProps> = ({
  incidentsSelectedRows,
  datasource,
  setDataSource,
  setRows,
  setRowKeys,
}) => {
  const dispatch = useDispatch();

  const [confirmText, setText] = useState<string>("");
  const [simText, setSimText] = useState<string>("");

  const $liveIncidents = useSelector<StoreState, GetIncident[]>(
    (state) => state.incidents.liveIncidents
  );
  const onClickStartSim = () => {
    let triggers: { nodeId: string; triggerName: string }[] = [];
    incidentsSelectedRows.map((inc: any) => {
      if (inc?.node > 100) {
        return;
      }
      triggers.push({ nodeId: inc?.node, triggerName: inc?.event });
    });

    dispatch(addTriggerRedux(triggers));
    dispatch(setAppState("simulation"));
    dispatch(resetModal({ resetModal: false }));
    //@ts-ignore
    dispatch(resetSimulations());
    dispatch(toggleNewSimulationModal(true));
    dispatch(toggleIncidentModal(false));
  };
  const deleteIncidentsFunc = () => {
    if (incidentsSelectedRows.length === 0) {
      setDataSource([]);
    } else {
      setDataSource(
        datasource.filter((i: any) => !incidentsSelectedRows.includes(i))
      );
    }
    setRows([])
    setRowKeys([]);
  };

  const deleteText = () => {
    let text = "";
    if (incidentsSelectedRows.length === 0) {
      text = "Are you sure you want to delete all live incidents?";
    } else {
      text = `Are you sure you want to delete ${incidentsSelectedRows.length} selected live incidents?`;
    }
    setText(text);
  };

  const startSimText = () => {
    let text = "";
    if (incidentsSelectedRows?.every((i: any) => i?.node < 100)) {
      text =
        "Are you sure you want to start simulation for the selected live incidents?";
    } else {
      text = `Some incidents don't support simulation. Start with the rest?`;
    }
    setSimText(text);
  };

  const reportToCoordAndDeleteFromTable = async () => {
    for (const inc of incidentsSelectedRows) {
      const row: any = $liveIncidents.filter(
        (i: any) => i?._id === inc?._id
      );

      if (row.length > 0) {
        const res = await reportToCoordCenter(row[0])      
        if (res === true) {
          setDataSource(datasource.filter((i: any) => i?._id !== inc?._id))
        }
      }
    }
  };


  const reportToCoordCenter = async (
    incident: any,
  ): Promise<any> => {
    try {
      const nodeId = incident?.properties?.node;
      const node = nodes.find((i) => i.id === nodeId);
      if (!node) {
        console.log("could not find node with id ", nodeId, node, incident);
        message.error("Report to coordination center failed");
        return false;
      }
      const payload = {
        type: "Feature",
        properties: {
          node: incident?.properties?.node,
          event: incident?.properties?.event,
          title: node?.label,
          incident_priority: incident?.properties?.incident_priority,
          incident_severity: incident?.properties?.incident_severity,
          scale_of_impacts: incident?.properties?.scale_of_impacts,
          report_recipients: incident?.properties?.scale_of_impacts,
        },
        report: false,
      } as CreateCustomIncidentPayload;

      console.log("incident payload ,", payload);
      const newIncident: any = await reportIncidentById(incident._id, payload);
      console.log("incident response ,", newIncident);
      if (newIncident) {
        message.success("Report to coordination center sent successfully");
        return true;
      }
    } catch (e) {
      console.error("notif report error ", e);
      message.error("Report to coordination center failed");
      return false
    }
  };


  return (
    <div className='card-title-container'>
      <span className='card-title'>Live View</span>
      <div style={{ transform: "translateY(2px)", marginRight: "5px" }}>
        {incidentsSelectedRows.length > 0 && (
          <Tooltip title='Report to coordination center' placement='bottom'>
            <Popconfirm
              placement='topLeft'
              title={"Report all incidents to coordination center?"}
              onConfirm={reportToCoordAndDeleteFromTable}
              okText='Yes'
              cancelText='No'
            >
              <a
                style={{
                  color: "#bfbfbf",
                  marginRight: "10px",
                  fontSize: "16px",
                }}
              >
                <FormOutlined style={{ transform: "translateY(-2px)" }} />
              </a>
            </Popconfirm>
          </Tooltip>
        )}
        {incidentsSelectedRows?.some((i: any) => i?.node < 100) && (
          <Tooltip title='Start simulation' placement='bottom'>
            <Popconfirm
              placement='topLeft'
              title={simText}
              onConfirm={onClickStartSim}
              okText='Yes'
              cancelText='No'
            >
              <a
                onClick={startSimText}
                style={{
                  color: "#bfbfbf",
                  marginRight: "10px",
                  fontSize: "16px",
                }}
              >
                <MenuFoldOutlined style={{ transform: "translateY(-2px)" }} />
              </a>
            </Popconfirm>
          </Tooltip>
        )}
        <span className='add-anchor'>
          <Popconfirm
            placement='topLeft'
            title={confirmText}
            onConfirm={deleteIncidentsFunc}
            okText='Yes'
            cancelText='No'
          >
            <a
              onClick={deleteText}
              style={{
                color: "#bfbfbf",
                fontSize: "18px",
              }}
            >
              <DeleteOutlined style={{ transform: "translateY(-1px)" }} />
            </a>
          </Popconfirm>
        </span>
      </div>
    </div>
  );
};