import React, { useEffect, useRef, useState } from "react";
import { StoreState } from "../../redux/state";
import { useDispatch, useSelector } from "react-redux";
import {
  getExecutionsAction,
  getSimulation,
  resetSimulations,
  setActiveExecution,
  setMitigationActions,
  setResilience,
} from "../../redux/simulationsSlice";
import { Tooltip, Tabs, Card, Table, Badge } from "antd";

import {
  addViewIncidents,
  getIncidentsAction,
  removeViewIncidents,
} from "../../redux/incidentsSlice";
import { GetIncident, Incident, IncidentListRecord } from "../../api/incidents.api";
import { TabTable } from "./tabTable";
import { StyleVariables } from "../../styles/variables";
import Icon, { EyeOutlined } from "@ant-design/icons";
import { setAppState } from "../../redux/appSlice";
import { ReactComponent as IncidentLogo } from "../../images/flaticon/incidents.svg";
import { ReactComponent as SimulationsLogo } from "../../images/flaticon/simulations.svg";
import { ReactComponent as LiveViewLogo } from "../../images/flaticon/live-view.svg";
import { ResilienceIndicator } from "../Indicators/resilience";
import { SimulationHeaderTab } from "./simulationTab.header";
import { IncidentsHeaderTab } from "./incidentsTab.header";
import { Execution } from "../../api/simulations.api";
import { Button } from "../ui/components";
import { styleShowHideTabButton, styleTabsButton } from "../Menu/menu-styled-components";
import useGetRealTimeData from "../../api/real-time-hook";
import RealTimeLoader from "../Real-Time-Loader/real-time-loader";
import moment from "moment";
import { LiveViewHeaderTab } from "./liveViewTab.header";

type FormProps = { };
export const TabPanelModal: React.FC<FormProps> = ({ }) => {
  const dispatch = useDispatch();

  const { TabPane } = Tabs;

    const [unseenDatasourceLiveIncidents, setUnseenDatasourceLiveIncidents] = useState<
      any[]
    >([]);

  const [datasourceLiveIncidents, setDatasourceLiveIncidents] = useState<any[]>([]);
  const [liveincidentSelectedRows, setliveIncidentSelectedRows] = useState([]);
  const [liveincidentSelectedRowsKeys, setliveIncidentSelectedRowsKeys] =
    useState([]);
  
  const [datasourceIncidents, setDatasourceIncidents] = useState<any[]>([]);
  const [incidentSelectedRows, setIncidentSelectedRows] = useState([]);
  const [incidentSelectedRowsKeys, setIncidentSelectedRowsKeys] = useState([]);

  const [datasourceExecutions, setDatasourceExecutions] = useState<any[]>([]);
  const [executionSelectedRowsKeys, setExecutionSelectedRowsKeys] = useState([]);
  const [executionSelectedRows, setExecutionSelectedRows] = useState([]);
  const [showSideTabs, setShowSideTabs] = useState<boolean>(true);

  const $executions = useSelector(
    (state: StoreState) => state.simulations.executions
  );
  const $liveIncidents = useSelector<StoreState, GetIncident[]>(
    (state) => state.incidents.liveIncidents
  );
  const $incidentPagination = useSelector<StoreState, any>(
    (state) => state.incidents.incidentPagination
  );
  const $executionPagination = useSelector<StoreState, any>(
    (state) => state.simulations.executionPagination
  );

  const $incidents = useSelector<StoreState, GetIncident[]>(
    (state) => state.incidents.incidents
  );

  const [, , realTimeLastSync] = useGetRealTimeData();


  const getExecutions = () => {
    dispatch(setResilience(null));
    dispatch(setMitigationActions([]));
    dispatch(setAppState("executions"));
    //@ts-ignore
    dispatch(
      getExecutionsAction({ median_simulation: true, page: 1, pageSize: 5 })
    );
    dispatch(removeViewIncidents());
    //@ts-ignore
    dispatch(resetSimulations());

    setIncidentSelectedRows([]);
    setExecutionSelectedRowsKeys([]);
  };

  const getIncidents = () => {
    dispatch(setMitigationActions([]));
    dispatch(setAppState("incidents"));
    dispatch(getIncidentsAction({ page: 1, pageSize: 5 }));
    dispatch(removeViewIncidents());

    setExecutionSelectedRows([]);
    setExecutionSelectedRowsKeys([]);
  };

  const clickIncidentView = (record: IncidentListRecord, datasource: any) => {
    const row: Incident[] = datasource.filter((i: any) => i?._id === record?._id)
    if (row.length === 0) {
      console.log("no row found with id ", record?._id)
      return
    }
    dispatch(addViewIncidents(row[0]));
  };
  const clickExecutionView = (record: any) => {

    const execution:Execution = $executions[record.key];
    const medianSim: any = execution.simulations.find(
      (i) => i.medianSimulation
    );
      
    //@ts-ignore
    dispatch(resetSimulations());
    dispatch(setActiveExecution(execution));
    dispatch(setAppState("executions"));
    dispatch(setMitigationActions([]));
    dispatch(setResilience(record.resilience));
    dispatch(getSimulation(medianSim._id));
  };
  const incidentColumnsTable = [
    {
      title: "Id",
      dataIndex: "_id",
      key: "_id",
      hidden: true,
    },
    {
      title: "",
      dataIndex: "info",
      key: "info",
      width: "80%",
      render: (text: string) => (
        <>
          <div style={{ textAlign: "center" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "14px",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  width: "70%",
                  marginBottom: "0",
                  color: "white",
                }}
              >
                {text.split("@")[0]}
              </div>
              <div
                style={{
                  marginBottom: "0",
                  color: "white",
                  textAlign: "right",
                  width: "42%",
                }}
              >
                {text.split("@")[1]}
              </div>
            </div>
            <p
              style={{
                marginBottom: "0",
                fontStyle: "italic",
                opacity: "0.9",
                fontSize: "12px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {text.split("@")[2]}
            </p>
          </div>
        </>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sortDirections: ["descend", "ascend"],
      sorter: (a: any, b: any) => a?.title?.length - b?.title?.length,
      hidden: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sortDirections: ["descend", "ascend"],
      sorter: (a: any, b: any) =>
        a?.description?.length - b?.description?.length,
      hidden: true,
    },
    {
      title: "Event",
      dataIndex: "event",
      key: "event",
      sortDirections: ["descend", "ascend"],
      sorter: (a: any, b: any) => a?.event?.length - b?.event?.length,
      hidden: true,
    },
    {
      title: "Creation Date",
      dataIndex: "creationDate",
      key: "creationDate",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) =>
        new Date(a?.creationDate)?.getTime() -
        new Date(b?.creationDate)?.getTime(),
      hidden: true,
    },
    {
      title: "",
      dataIndex: "action",
      width: "10%",
      render: (text: any, record: any) => (
        <span>
          <Tooltip title='Watch current incident' placement='bottom'>
            <a
              style={{ color: StyleVariables.primaryHoverColor }}
              onClick={() => clickIncidentView(record, $incidents)}
            >
              <EyeOutlined />
            </a>
          </Tooltip>
        </span>
      ),
    },
  ].filter((item) => !item.hidden);

    const liveIncidentColumnsTable = [
      {
        title: "Id",
        dataIndex: "_id",
        key: "_id",
        hidden: true,
      },
      {
        title: "Live Incidents",
        dataIndex: "info",
        key: "info",
        width: "80%",
        render: (text: string) => (
          <>
            <div style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "14px",
                }}
              >
                <div
                  style={{
                    textAlign: "left",
                    width: "70%",
                    marginBottom: "0",
                    color: "white",
                  }}
                >
                  {text.split("@")[0]}
                </div>
                <div
                  style={{
                    marginBottom: "0",
                    color: "white",
                    textAlign: "right",
                    width: "42%",
                  }}
                >
                  {text.split("@")[1]}
                </div>
              </div>
              <p
                style={{
                  marginBottom: "0",
                  fontStyle: "italic",
                  opacity: "0.9",
                  fontSize: "12px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {text.split("@")[2]}
              </p>
            </div>
          </>
        ),
      },
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        sortDirections: ["descend", "ascend"],
        sorter: (a: any, b: any) => a?.title?.length - b?.title?.length,
        hidden: true,
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        sortDirections: ["descend", "ascend"],
        sorter: (a: any, b: any) =>
          a?.description?.length - b?.description?.length,
        hidden: true,
      },
      {
        title: "Event",
        dataIndex: "event",
        key: "event",
        sortDirections: ["descend", "ascend"],
        sorter: (a: any, b: any) => a?.event?.length - b?.event?.length,
        hidden: true,
      },
      {
        title: "Creation Date",
        dataIndex: "creationDate",
        key: "creationDate",
        sortDirections: ["descend", "ascend"],
        defaultSortOrder: "descend",
        sorter: (a: any, b: any) =>
          new Date(a?.creationDate)?.getTime() -
          new Date(b?.creationDate)?.getTime(),
        hidden: true,
      },
      {
        title: "",
        dataIndex: "action",
        width: "10%",
        render: (text: any, record: any) => (
          <span>
            <Tooltip title='Watch current incident' placement='bottom'>
              <a
                style={{ color: StyleVariables.primaryHoverColor }}
                onClick={() => clickIncidentView(record, $liveIncidents)}
              >
                <EyeOutlined />
              </a>
            </Tooltip>
          </span>
        ),
      },
    ].filter((item) => !item.hidden);


  useEffect(() => {
    setIncidentSelectedRowsKeys([]);
    setIncidentSelectedRows([]);
    setDatasourceIncidents(
      $incidents.map((incident, index) => {
        const formattedDate = moment
          .utc(incident.properties.creation_date_time_utc)
          .local()
          .format("YYYY-MM-DD HH:mm:ss");

        const info = `${incident.properties.title}@${incident.properties.event}@${formattedDate}`;

        return {
          _id: incident._id,
          key: index,
          info: info,
          title: incident.properties.title,
          description: incident.properties.description,
          creationDate: incident.properties.creation_date_time_utc,
          event: incident.properties.event,
          node: incident.properties.node,
        };
      })
    );
  }, [$incidents]);

  const executionsColumnsTable = [
    {
      title: "Id",
      dataIndex: "_id",
      key: "_id",
      hidden: true,
    },
    {
      title: "",
      dataIndex: "info",
      key: "info",
      width: "80%",
      render: (text: string) => (
        <>
          <div style={{ textAlign: "center" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "14px",
              }}
            >
              <div
                style={{
                  marginBottom: "0",
                  color: "white",
                  transform: "translateY(9px)",
                }}
              >
                {text.split("@")[0]}
              </div>
              <div style={{ marginBottom: "0", color: "white" }}>
                {text.split("@")[1]}
              </div>
            </div>
            <p
              style={{
                marginBottom: "0",
                fontStyle: "italic",
                opacity: "0.9",
                fontSize: "12px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {text.split("@")[2]}
            </p>
          </div>
        </>
      ),
    },
    {
      title: "Event",
      dataIndex: "event",
      key: "event",
      sortDirections: ["descend", "ascend"],
      sorter: (a: any, b: any) => a.event.length - b.event.length,
      hidden: true,
    },
    {
      title: "Creation Date",
      dataIndex: "creationDate",
      key: "creationDate",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) =>
        new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime(),
      hidden: true,
    },
    {
      title: "Resilience",
      dataIndex: "resilience",
      key: "resilience",
      sortDirections: ["descend", "ascend"],
      sorter: (a: any, b: any) => a.resilience - b.resilience,
      hidden: true,
    },
    {
      title: "",
      dataIndex: "action",
      width: "10%",
      render: (text: any, record: any) => (
        <span>
          <Tooltip title='Watch current simulation' placement='bottom'>
            <a
              style={{ color: StyleVariables.primaryHoverColor }}
              onClick={() => clickExecutionView(record)}
            >
              <EyeOutlined />
            </a>
          </Tooltip>
        </span>
      ),
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    setExecutionSelectedRows([]);
    setExecutionSelectedRowsKeys([]);
    setDatasourceExecutions(
      $executions.map((execution, index) => {
        const medianSim = execution.simulations.find((i) => i.medianSimulation);
        
        const formattedDate = moment.utc(medianSim?.timestamp)
          .local()
          .format("YYYY-MM-DD HH:mm:ss");

        const info = `${execution.events[0].event}@${execution?.resilience?.toFixed(4) || ""}@${formattedDate}`;
        return {
          _id: execution._id,
          key: index,
          info: info,
          creationDate: formattedDate,
          resilience: execution?.resilience?.toFixed(4) || "",
          event: execution.events[0].event,
        };
      })
    );
  }, [$executions]);

  useEffect(() => {
    if (!$liveIncidents) {
      return;
    }

    const newData = $liveIncidents.map((incident, index) => {
      const formattedDate = moment
        .utc(incident?.properties?.creation_date_time_utc || "")
        .local()
        .format("YYYY-MM-DD HH:mm:ss");

      const info = `${incident.properties.title}@${incident.properties.event}@${formattedDate}`;

      return {
        _id: incident._id,
        key: index + datasourceLiveIncidents.length,
        info: info,
        title: incident.properties.title,
        description: incident.properties.description,
        creationDate: incident.properties.creation_date_time_utc,
        event: incident.properties.event,
        node: incident.properties.node,
      };
    });
    
    setIncidentSelectedRowsKeys([]);
    setIncidentSelectedRows([]);
    setDatasourceLiveIncidents([...datasourceLiveIncidents, ...newData]);

    if (showSideTabs === false) {
      setUnseenDatasourceLiveIncidents([
        ...unseenDatasourceLiveIncidents,
        ...newData,
      ]);
    }

  }, [$liveIncidents])

  const onChangePageExecutions = (page: number, pageSize: number) => {
    dispatch(getExecutionsAction({ median_simulation: true, page, pageSize }));
  };
  const onChangePageIncidents = (page: number, pageSize: number) => {
    dispatch(
      getIncidentsAction({
        page,
        pageSize,
      })
    );
  };

  function callbackTabClicked(key: any) {
    switch (key) {
      case "1": {
        console.log("First Tab");
        dispatch(setAppState("real-time"));
        //live;
        break;
      }
      case "2": {
        console.log("Second Tab");
        getExecutions();
        //simulations;
        break;
      }
      case "3": {
        //incidents;
        console.log("Third Tab");
        getIncidents();
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  }
  
  return (
    <>
      <div style={styleTabsButton}>
        <div style={styleShowHideTabButton}>
          <Tooltip title='Minimize'>
            {/* @ts-ignore */}
            <Badge
              count={unseenDatasourceLiveIncidents.length}
              overflowCount={99}
            >
              <Button
                style={{
                  background: "transparent",
                  fontSize: "18px",
                  borderColor: "transparent",
                  color: StyleVariables.primaryColor
                }}
                icon={"minus-square"}
                onClick={() => {
                  if (showSideTabs === false) {
                    setUnseenDatasourceLiveIncidents([]);
                  }
                  setShowSideTabs(!showSideTabs);
                }}
              />
            </Badge>
          </Tooltip>
        </div>

        {showSideTabs && (
          //@ts-ignore
          <Tabs
            defaultActiveKey='1'
            onChange={callbackTabClicked}
            type='card'
            className=''
          >
            {
              //@ts-ignore
              <TabPane
                className='tab-pane'
                tab={
                  <Tooltip title='Watch real time data'>
                    <LiveViewLogo className='tab-icon' />
                  </Tooltip>
                }
                key='1'
              >
                <Card
                  title={
                    <LiveViewHeaderTab
                      incidentsSelectedRows={liveincidentSelectedRows}
                      datasource={datasourceLiveIncidents}
                      setDataSource={setDatasourceLiveIncidents}
                      setRows={setliveIncidentSelectedRows}
                      setRowKeys={setliveIncidentSelectedRowsKeys}
                    />
                  }
                  actions={[]}
                >
                  <TabTable
                    datasource={datasourceLiveIncidents}
                    columns={liveIncidentColumnsTable}
                    rowSelection={{
                      selectedRowKeys: liveincidentSelectedRowsKeys,
                      selectedRows: liveincidentSelectedRows,
                      onChange: (selectedRowKeys: any, selectedRows: any) => {
                        setliveIncidentSelectedRows(selectedRows);
                        setliveIncidentSelectedRowsKeys(selectedRowKeys);
                      },
                    }}
                    total={datasourceLiveIncidents.length}
                    onChangePage={(page: number, pageSize: number) => {}}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      position: "absolute",
                      bottom: "0px",
                      width: "100%",
                      height: "53px",
                    }}
                  >
                    <RealTimeLoader lastSync={realTimeLastSync} />
                    <ResilienceIndicator />
                  </div>
                </Card>
              </TabPane>
            }
            {
              //@ts-ignore
              <TabPane
                className='tab-pane'
                tab={
                  <Tooltip title='Watch or start simulations'>
                    <SimulationsLogo className='tab-icon' />
                  </Tooltip>
                }
                key='2'
              >
                <Card
                  actions={[<ResilienceIndicator />]}
                  title={
                    <SimulationHeaderTab
                      executionSelectedRows={executionSelectedRows}
                    />
                  }
                >
                  <TabTable
                    datasource={datasourceExecutions}
                    columns={executionsColumnsTable}
                    rowSelection={{
                      selectedRowKeys: executionSelectedRowsKeys,
                      selectedRows: executionSelectedRows,
                      onChange: (selectedRowKeys: any, selectedRows: any) => {
                        setExecutionSelectedRows(selectedRows);
                        setExecutionSelectedRowsKeys(selectedRowKeys);
                      },
                    }}
                    total={$executionPagination.total}
                    onChangePage={onChangePageExecutions}
                  />{" "}
                </Card>
              </TabPane>
            }
            {
              //@ts-ignore
              <TabPane
                className='tab-pane'
                tab={
                  <Tooltip title='See incidents'>
                    <IncidentLogo className='tab-icon' />
                  </Tooltip>
                }
                key='3'
                onTabClick={callbackTabClicked}
              >
                <Card
                  title={
                    <IncidentsHeaderTab
                      incidentsSelectedRows={incidentSelectedRows}
                    />
                  }
                >
                  <TabTable
                    datasource={datasourceIncidents}
                    columns={incidentColumnsTable}
                    rowSelection={{
                      selectedRowKeys: incidentSelectedRowsKeys,
                      selectedRows: incidentSelectedRows,
                      onChange: (selectedRowKeys: any, selectedRows: any) => {
                        setIncidentSelectedRows(selectedRows);
                        setIncidentSelectedRowsKeys(selectedRowKeys);
                      },
                    }}
                    total={$incidentPagination.total}
                    onChangePage={onChangePageIncidents}
                  />
                </Card>
              </TabPane>
            }
          </Tabs>
        )}
      </div>
    </>
  );
};
