import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TimelineLabel } from "../components/Timeline-control/Timeline";
import { INewTrigger, Trigger } from "../models/trigger";
import { CreateSimulations, CreateSimulationsPayload, Execution, MitigationActionResponse, Simulation, deleteExecutionsApi, getExecutionsApi, getMitigationActionsApi, getMitigationActionsStatusApi, getSimulationByIdApi } from "../api/simulations.api";
import { _TRIGGERS } from "../data/triggers/triggers";


type NodeName = string;
type MitigationAction = string;
const nodes: Trigger[] = _TRIGGERS;

export interface SimulationState {
  loadingLabel: string;
  loading: boolean;
  resetModal: boolean;
  nodeId: number | null
  timelineLabels: TimelineLabel[];
  activeTimelineLabel: string | null;
  nodeClicked: boolean
  activeSimulation: Simulation | null,
  activeExecution: Execution | null,
  simulations: Simulation[];
  executions: Execution[];
  newSimulationModalIsOpen: boolean;
  triggersToAdd: { nodeId?: number, triggerName?: string }[] | [];
  activeResilience: string | null;
  mitigationActions: [NodeName, MitigationAction][];
  mitigationActionsStatus: any;
  executionPagination: { total: number, page: number, pageSize: number }
}

const initialState: SimulationState = {
  loadingLabel: "",
  loading: false,
  resetModal: false,
  nodeId: null,
  timelineLabels: [],
  activeTimelineLabel: null,
  nodeClicked: false,
  activeExecution: null,
  activeSimulation: null,
  simulations: [],
  executions: [],
  newSimulationModalIsOpen: false,
  triggersToAdd: [],
  activeResilience: null,
  mitigationActions: [],
  mitigationActionsStatus:{},
  executionPagination: {
    total: 0,
    page: 0,
    pageSize: 0
  }
};


export const getMitigationActions = createAsyncThunk(
  "simulations/getMitigationActions",
  async (payload: any): Promise<MitigationActionResponse> => {
    const response = await getMitigationActionsApi(payload)
    return response;
  }
);

export const getMitigationActionsStatus = createAsyncThunk(
  "simulations/getMitigationActionsStatus",
  async (actionId: any): Promise<MitigationActionResponse> => {
    const response = await getMitigationActionsStatusApi(actionId)
    return response;
  }
);

export const getSimulations = createAsyncThunk(
  "simulations/getSimulations",
  async (payload: { newTriggers: INewTrigger[]; simsNum: number }) => {
    console.log({ payload });
    //TODO: Fix this
    // const response = await axios.get("/api/simulations");
    // return MockData.Data;
    return {};
    // return response.data;
  }
);
export const getSimulation = createAsyncThunk(
  "simulations/getSimulation",
  async (payload: string) => {
    const response = await getSimulationByIdApi(payload)
    console.log('getSimulation res', response)
    return response;
  }
);
export const getExecutionsAction = createAsyncThunk(
  "simulations/getExecutions",
  async (payload: { page: number, pageSize: number, median_simulation: boolean  }) => {
    const response: any = await getExecutionsApi(payload)
    return response;
  }
);
export const deleteExecutions = createAsyncThunk(
  "simulations/deleteExecutions",
  async (payload: any) => {
    const response = await deleteExecutionsApi(payload)
    return response;
  }
);

export const createSimulations = createAsyncThunk(
  "simulations/createSimulations",
  async (payload: CreateSimulationsPayload) => {
    console.log("createSimulations", payload);
    const response = await CreateSimulations(payload)
    return response;
  }
);
export const simulationsSlice = createSlice({
  name: "simulations",
  initialState,
  reducers: {
    toggleNewSimulationModal: (state: any, { payload }: any) => {
      return { ...state, newSimulationModalIsOpen: payload };
    },
    setResilience: (state: any, { payload }: any) => {
      let activeResilience = null;

      if (payload) {
        activeResilience = Number(payload).toPrecision(4)
      }
      return { ...state, activeResilience };
    },
    setMitigationActions: (state: any, { payload }: any) => {
      return { ...state, mitigationActions: payload };
    },
    setActiveExecution: (state: any, { payload }: any) => {
      return { ...state, activeExecution: payload };
    },

    addTriggerRedux: (state: any, { payload }: any) => {
      return { ...state, triggersToAdd: payload };
    },
    resetSimulations: (state: any) => {
      return {
        ...state,
        activeTimelineLabel: null,
        activeSimulation: null,
        timelineLabels: [],
        loadingLabel: '',
        loading: false,
      };
    },
    setTimelineStep: (state: any, { payload }: { payload: string }) => {
      console.log("setTimelineStep payload", payload);
      return { ...state, activeTimelineLabel: payload };
    },
    getSimulationsError: (state: any, payload: any) => {
      console.log("Data error", payload);
      return { ...state, loading: false };
    },
    createSimulationsError: (state: any, { payload }: any) => {
      console.log('Create sim err', payload)
    },
    selectNode: (state: any, { payload }: any) => {
      console.log("Data select node", payload);
      return { ...state, nodeId: payload.nodeId };
    },
    //@ts-ignore
    resetModal: (state: { triggersToAdd: any; }, { payload }: any) => {
      let triggersToAdd = state.triggersToAdd
      if (payload.resetModal) triggersToAdd = []
      return { ...state, resetModal: payload.resetModal, triggersToAdd };
    },
  },
  //@ts-ignore
  extraReducers: (builder: any) => {
    builder
      .addCase(getSimulations.fulfilled, (state: any, { payload: data }: any) => {
        console.log({ state, data });
        return { ...state, data, loading: true };
      })
      .addCase(createSimulations.pending, (state: any, payload: any) => {
        return { ...state, loading: true, loadingLabel: 'Initializing simulations' }
      })
      .addCase(createSimulations.fulfilled, (state: { executions: any; }, { payload }: any) => {
        const currentExecutions = state.executions;
        return { ...state, loading: false, loadingLabel: '', executions: [...currentExecutions, payload], activeExecution: payload };
      })
      .addCase(getExecutionsAction.pending, (state: any, { payload }: any) => {
        return { ...state, loading: true, loadingLabel: '' };
      })
      .addCase(getExecutionsAction.fulfilled, (state: any, { payload }: any) => {
        return { ...state, executions: payload.executions, loading: false, executionPagination: { total: payload.total, page: payload.page, pageSize: payload.page_size } };
      })
      .addCase(getMitigationActionsStatus.pending, (state: any, { payload }: any) => {
        console.log(payload)
        return { ...state, loading: false, loadingLabel: '' };
      })
      .addCase(getMitigationActionsStatus.fulfilled, (state: any, { payload }: any) => {
        console.log(payload)
        return { ...state, mitigationActionsStatus: payload, loading: false };
      })
      .addCase(getMitigationActions.fulfilled, (state: { timelineLabels: any[]; activeSimulation: { simulation_per_time: any; }; }, { payload }: { payload: MitigationActionResponse }) => {
        const mitigationActions: [string, string][] = payload.mitigation_actions.map(([nodeId, action]) => {
          const node = nodes.find(i => i.id === nodeId)
          return [node ? node.label : "NA", action]
        })

        const mitigationKey = "RN"

        if (state.timelineLabels.find((i: { label: string; }) => i.label === mitigationKey) !== undefined) {
          console.log("mitigation exists. return")
          return { ...state, loading: false }
        }
        const timelineLabels = [...state.timelineLabels, { label: mitigationKey, value: mitigationKey }];
        const mitigationGraph = payload.graph["0"]

        const simulation_per_time = { ...state.activeSimulation?.simulation_per_time, [mitigationKey]: mitigationGraph };
        const activeSimulation = { ...state.activeSimulation, simulation_per_time, resilience: payload.resilience } as any

        return { ...state, mitigationActions, timelineLabels, activeSimulation, loading: false };
      })
      .addCase(getMitigationActions.pending, (state: any, { payload }: any) => {
        return { ...state, loading: true, loadingLabel: 'Retrieving data' }
      })
      .addCase(getSimulation.pending, (state: any, { payload }: any) => { 
        return { ...state, loading: true, loadingLabel: ''}
      })
      .addCase(getSimulation.fulfilled, (state: any, { payload }: any) => {
        const simulation: Simulation = payload;
        const simData = simulation.simulation_per_time
        let timelineLabels: TimelineLabel[] = [];
        for (const key in simData) {
          timelineLabels.push({ label: `T-${key}`, value: key });
        }
        let activeTimelineLabel = null;
        console.log('timelineLabels', timelineLabels)
        activeTimelineLabel = timelineLabels[0].value;
        console.log('active sim', simulation.simulation_per_time)
        return {
          ...state,
          activeTimelineLabel,
          activeSimulation: simulation,
          timelineLabels,
          loadingLabel: '',
          loading: false,
        };
      })
      .addCase(deleteExecutions.pending, (state: any, { payload }: any) => {
        return { ...state, loading: true, loadingLabel: 'Deleting Executions' };
      })
      .addCase(deleteExecutions.fulfilled, (state: any, { payload }: any) => {
        if (payload.all === true) return { ...state, loading: false, executions: [] }

        const executions = state.executions.filter((i: any) => !payload.ids.includes(i._id))
        return { ...state, loading: false, executions };
      })
  },
});

// Action creators are generated for each case reducer function
export const { resetSimulations, selectNode, setActiveExecution, setResilience, addTriggerRedux, setMitigationActions, setTimelineStep, resetModal, toggleNewSimulationModal } =
  simulationsSlice.actions;

export default simulationsSlice.reducer;
