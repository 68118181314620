import { Dataset } from "../types/map.types";
import getDatasetConfig from "./kepler.config";

export type ApiDataset = { [key: string]: GeoJSON.FeatureCollection };
export interface DatasetConfig { color: string, opacity: number }
function prepareApiDataset(_datasets: ApiDataset, configuration?: DatasetConfig): {
  config: any;
  datasets: Dataset[];
  NonKeplerDatasets: ApiDataset
} {
  return _prepareApiDatasetOld(_datasets, configuration)
}

export function _prepareApiDatasetOld(_datasets: ApiDataset, configuration?: DatasetConfig): {
  config: any,
  datasets: Dataset[],
  NonKeplerDatasets: ApiDataset
} {


  let layers: any = [];
  let datasets: Dataset[] = [];


  let newDatasets: ApiDataset = {
    ..._datasets,
    // //@ts-ignore
    // metro_test: METRO_DATA,
    // //@ts-ignore
    // services: SERVICES_DATA,
    // //@ts-ignore
    // tolls: TOLLS_DATA
  }
  // delete newDatasets['TOLLS_DATA']
  // delete newDatasets['SERVICES']
  // delete newDatasets['AMETRO_STATIONS']
  //@ts-ignore
  // const { KeplerDatasets, NonKeplerDatasets } = splitKeplerAndNonKeplerDatasets(newDatasets)
  const NonKeplerDatasets = newDatasets
  // for (const key in KeplerDatasets) {
  //   //@ts-ignore
  //   const _data = KeplerDatasets[key]

  //   try {
  //     const id = randomString(8);
  //     const dataId = randomString(8);
  //     const label = key;
  //     const layer = createLayer({ id, dataId, label });
  //     //TODO: create datasets with the same id as tje dataId
  //     layers.push(layer);
  //     const dataset = createDataSet({
  //       id: dataId,
  //       label,//@ts-ignore
  //       geoJsonData: { ..._data },
  //     });
  //     datasets.push(dataset);
  //   } catch (error) {
  //     console.log(`Dataset ${key} Error`, _data, error);
  //   }
  // }


  const config = getDatasetConfig(layers);
  return { config, datasets, NonKeplerDatasets };
}


export { prepareApiDataset };
