import React, { useEffect, useState } from 'react'
import './triggers.scss'
import { StyleVariables } from '../../styles/variables'
import { TriggerForm } from './Trigger-form'
import { Row, Col, Form, message } from 'antd'
import { type INewTrigger, type Trigger } from '../../models/trigger'
import { _TRIGGERS } from '../../data/triggers/triggers'
import { Button, InputNumber } from '../ui/components'
import { TriggerList } from './Triggers-list'
import store from '../../redux/store'
import { useSelector } from 'react-redux'
import {
  createSimulations, resetModal, selectNode, setMitigationActions, toggleNewSimulationModal,
} from '../../redux/simulationsSlice'
import { FormComponentProps } from "antd/lib/form";
import { CreateSimulationsPayload } from '../../api/simulations.api'
import { FormContainer } from '../ui/Forms'
import { setAppState } from '../../redux/appSlice'

const nodes: Trigger[] = _TRIGGERS

interface SimulationFormProps extends FormComponentProps {}

const _SimulationForm: React.FC<SimulationFormProps> = ({
  form,
}: SimulationFormProps) => {
  const [newTriggers, setNewTriggers] = useState<INewTrigger[]>([]);
  const [selectedNodeId, setSelectedNodeId] = useState<number | null>(null);
  const [simsNum, setSimsNum] = useState<number>(1);


  const $nodeId = useSelector((state: any) => state.simulations.nodeId);
  const $resetModal = useSelector((state: any) => state.simulations.resetModal);
  const $triggersToAdd = useSelector(
    (state: any) => state.simulations.triggersToAdd
  );

  useEffect(() => {
    if ($resetModal === true) {
      setSimsNum(1);
      setNewTriggers([]);
    }
  }, [$resetModal]);

  useEffect(() => {
    if (nodes.find((tr) => tr.id == $nodeId)) {
      setSelectedNodeId($nodeId);
    } else {
      setSelectedNodeId(null);
    }
  }, [newTriggers, $nodeId]);

  useEffect(() => {
    if ($triggersToAdd.length === 0) return;

    let addTriggers: INewTrigger[] = [];
    $triggersToAdd.map((trig: any) => {
      const nodeName: any = nodes.find((i) => i.id == trig.nodeId)?.label;
      const trigger: INewTrigger = {
        nodeId: trig.nodeId,
        nodeName,
        trigger: trig.triggerName,
      };

      if (trig.nodeId >= 100) {
        message.error("Some nodes don't support simulations")
        return;
      }

      addTriggers.push(trigger);
    });

    // TODO remove duplicates
    addTriggers = addTriggers.filter(
      (obj, index) =>
        addTriggers.findIndex(
          (item) => item.nodeId === obj.nodeId && item.trigger === obj.trigger
        ) === index
    );

    setNewTriggers([...newTriggers, ...[...addTriggers]]);
  }, [$triggersToAdd]);

  const newTrigger = (trigger: INewTrigger) => {
    if (
      newTriggers.find(
        (i) => i.nodeId == trigger.nodeId && i.trigger == trigger.trigger
      )
    ) {
      console.log("Trigger already exists. not adding.");
      return;
    }
    setNewTriggers([...newTriggers, trigger]);
  };

  const onRemoveTrigger = (trigger: INewTrigger) => {
    let updateTriggers = [...newTriggers];
    const index = updateTriggers.indexOf(trigger)
    if (index !== -1) {
      updateTriggers.splice(index, 1);
    }
    setNewTriggers(updateTriggers);
  };

  const triggersValidator = (rule: any, value: any, callback: any) => {
    if (!newTriggers || newTriggers.length === 0) return callback("Please add trigger");
    return callback();
  };

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    form.validateFields((err: any, data: SimulationFormProps) => {
      if (!err) {
        initSimulation();
      }
      console.error("Submit simulation form error: ", err);
    });
  };

  const initSimulation = () => {
    console.log({ newTriggers, simsNum });
    const payload: CreateSimulationsPayload = {
      // user_id: DEFAULT_USER_ID,
      events: newTriggers.map((tr) => {
        return { node: tr.nodeId, event: tr.trigger };
      }),
      simulations: simsNum,
      name: "",
      description: "",
    };

    store.dispatch(setMitigationActions([]));
    store.dispatch(createSimulations(payload) as any);
    store.dispatch(toggleNewSimulationModal(false));
    store.dispatch(setAppState("executions"));

    store.dispatch(selectNode({ nodeId: null }));
    store.dispatch(resetModal({ resetModal: true }));

    // dispatch(getSimulations({ newTriggers, simsNum }) as any)
  };

  const handleTriggerListChange = (changedFields: { triggers: any }) => {
    form.setFields({ newTriggers: changedFields.triggers });
    form.getFieldsValue();
    console.log(
      "Changed Fields form.getFieldsValue",
      form.getFieldsValue()
    );
  };

  const { getFieldDecorator } = form;
  return (
    <FormContainer>
      <Row gutter={16} style={{ color: StyleVariables.primaryColor }}>
        {/* <Col span={24}>
          <Header>Start Simulation</Header>
        </Col> */}
        <Col span={24}>
          <p>1. Please select a node that you want to trigger</p>
        </Col>
        <Col span={24}>
          <TriggerForm selectedNodeId={selectedNodeId} onNewTrigger={newTrigger} />
        </Col>
        <Col span={24}>
          <Form.Item>
            {getFieldDecorator("newTriggers", {
              rules: [{ validator: triggersValidator }],
            })(
              <TriggerList
                onRemoveTrigger={onRemoveTrigger}
                triggers={newTriggers}
                onChange={handleTriggerListChange}
              />
            )}

          </Form.Item>
        </Col>
        <Col span={24}>
          <p>
            2. Select the number of <strong>simulations</strong>
          </p>
        </Col>
        <Col span={24}>
          <InputNumber
            min={1}
            max={15}
            step={1}
            value={simsNum}
            onChange={(e) => {
              setSimsNum(e as number);
            }}
          />
        </Col>
        <Col span={8}>
          <p>&nbsp;</p>
          <Button rtype='primary' htmlType='submit' onClick={handleSubmit}>
            Start New Simulation
          </Button>
        </Col>
      </Row>
    </FormContainer>
  );
};

export const SimulationForm = Form.create<SimulationFormProps>({
  name: "simulation_form",
})(_SimulationForm);
