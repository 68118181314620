import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";

import demoReducer from "./reducers/index";

const reducers = combineReducers(demoReducer);


export const enhancers = [applyMiddleware(thunkMiddleware)];

const initialState = {};

// add redux devtools
let composeEnhancers = compose;
if ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    actionsBlacklist: [
      "realTimeData/getRealTimeData",
      "realTimeData/getRealTimeDataSuccess",
    ],
  });
}
export default createStore(
  reducers,
  initialState,
  composeEnhancers(...enhancers) as any
);
