import React, { useEffect } from "react";

type Props = {
  mitigationActions: [string, string][];
};

export const CreateTableComp: React.FC<Props> = ({ mitigationActions}) => {

    return (
      <div className='mitigationTableContainer'>
        <div className='mitigationTitle'>Mitigation Actions</div>
        <div className='mitigationRows'>
          {mitigationActions.map((item, index) => {
            return (
              <div key={index} className='mitigationRow'>
                <div key={index + 1}>{item[0]}</div>
                <div key={index + 2}>{item[1]}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
};
