import { Table } from "antd";
import React from "react";
import { MenuItem } from "../Menu/menu-styled-components";



type TableProps = {
  datasource: any[];
  columns: any[];
  rowSelection?: any;
  total: number;
  onChangePage?: any;
};



export const TabTable: React.FC<TableProps> = ({
  datasource,
  columns,
  rowSelection,
  total,
  onChangePage,
}) => {
  return (
    <>
      <Table
        scroll={{ y: 200 }}
        dataSource={datasource}
        columns={columns}
        rowSelection={rowSelection || null}
        pagination={{
          simple: true,
          onChange: onChangePage,
          pageSize: 5,
          position: "bottom",
          defaultCurrent: 1,
          showSizeChanger: false,
          total,
          showTotal: (total) => `${total} items`,
        }}
      />
    </>
  );
};