// {POST} http://localhost:8000/simulation/

import axios from "axios";
import { CREATE_EXECUTION_API, DELETE_EXECUTIONS, GET_EXECUTIONS_API, MITIGATION_ACTIONS, SIMULATIONS_API, getAccessToken } from "../constants";

export function CreateSimulations(createSimPayload: CreateSimulationsPayload): Promise<any> {
  const token = getAccessToken()
  const headers = { "Authorization": `Bearer ${token}` }
  
  return new Promise((resolve, reject) => {
    axios
      .post(CREATE_EXECUTION_API, createSimPayload, { headers })
      .then(function (response) {
        resolve(response!.data);
      })
      .catch(function (error) {
        reject(error);
      })
      .finally(function () { });
  });
}

export function getExecutionsApi(params: any): Promise<ExecutionsResponse> {
  const token = getAccessToken()
  const headers = { "Authorization": `Bearer ${token}` }

  return new Promise((resolve, reject) => {
    axios
      .get(GET_EXECUTIONS_API, {params, headers})
      .then(function (response) {
        resolve(response!.data);
      })
      .catch(function (error) {
        reject(error);
      })
      .finally(function () { });
  });
}


export function deleteExecutionsApi(params: any): Promise<ExecutionsResponse> {
  const token = getAccessToken()
  const headers = { "Authorization": `Bearer ${token}` }

  return new Promise((resolve, reject) => {
    axios
      .delete(DELETE_EXECUTIONS, { data: params, headers })
      .then(function (response) {
        resolve(params);
      })
      .catch(function (error) {
        reject(error);
      })
      .finally(function () { });
  });
}

export function getSimulationByIdApi(simdId: string): Promise<Simulation> {
  const token = getAccessToken()
  const headers = { "Authorization": `Bearer ${token}` }

  return new Promise((resolve, reject) => {
    axios
      .get(`${SIMULATIONS_API}${simdId}`, { headers })
      .then(function (response) {
        let data = response!.data
        if (data) {
          let transformed = data.simulation_per_time;
          //sim results are stringified so we parse them
          transformed = transformed.replace(/'/g, '"');
          for (let index = 30; index >= 0; index--) {
            transformed = transformed.replace(new RegExp(`${index}:`, "g"), `"${index}":`);
          }
          data = { ...data, simulation_per_time: JSON.parse(transformed) }
        }
        resolve(data);
      })
      .catch(function (error) {
        console.error(error)
        reject(error);
      })
      .finally(function () { });
  });
}

export function getMitigationActionsApi(payload: any): Promise<MitigationActionResponse> {
  const token = getAccessToken()
  const headers = { "Authorization": `Bearer ${token}` }

  console.log(payload)
  return new Promise((resolve, reject) => {
    axios
      .post(MITIGATION_ACTIONS, payload, { headers })
      .then(function (response) {
        resolve(response!.data);
      })
      .catch(function (error) {
        reject(error);
      })
      .finally(function () { });
  });
}

export function getMitigationActionsStatusApi(actionId: string): Promise<MitigationActionResponse> {
  const token = getAccessToken()
  const headers = { "Authorization": `Bearer ${token}` }

  return new Promise((resolve, reject) => {
    axios
      .get(`${MITIGATION_ACTIONS}/${actionId}`, { headers })
      .then(function (response) {
        resolve(response!.data);
      })
      .catch(function (error) {
        reject(error);
      })
      .finally(function () { });
  });
}


type SimulationEvent = {
  node: number
  event: string
}
export interface CreateSimulationsPayload {
  events: Array<SimulationEvent>
  simulations: number;
  user_id?: string;
  name: string;
  description: string;
}

// {Response}
export interface CreateSimulationsResponse {
  _id: string
  user_id: string
  simulations: Simulation[];
  timestamp: string
}


export interface Simulation {
  _id: string;
  user_id: string;
  execution_id: string;
  timestamp: string;
  medianSimulation: boolean;
  simulation_per_time: { [key: string]: any }
  resilience ?: number
}

export interface MitigationActionResponse {
  mitigation_actions: [number,string][];
  graph: { [key: string]: any };
  resilience: number
}

// {GET} http://localhost:8000/execution/5f5cabdc-6ed3-4313-b5de-2c9ab4b3781a/

export type ExecutionsResponse = Execution[]

export type Execution = {
  _id: string;
  user_id: string;
  name: string;
  description: string;
  timestamp: string;
  simulations: ExecSimulation[];
  events: SimulationEvent[]
  resilience:number;
  mitigation_actions_id: string;
}
type ExecSimulation = Pick<Simulation, '_id'> & Pick<Simulation, 'medianSimulation'> & Pick<Simulation, 'timestamp'>
// Get Simulation providing Simulation UUID
// {GET} http://localhost:8000/simulation/bc4b8908-ae7b-4939-bd19-bab77ba2173e/


export type SimulationStepValue = Record<string, GeoJSON.Point | GeoJSON.LineString | GeoJSON.Polygon>

