import React, { useState } from "react";
import { Routes, Route, Link } from "react-router-dom";

import NotFound from "../components/pages/no-match";
import Login from "../components/pages/login";
import { ProtectedRoute } from "../components/pages/route-guard";
import Map from "../components/pages/map";

function App() {

  return (
    <div>
      <Routes>
        <Route
          path='/'
          element={
            <ProtectedRoute>
              <Map />
            </ProtectedRoute>
          }
        />
        <Route path='/login' element={<Login />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </div>
  );
}
export default App;
