import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CreateCustomIncidentPayload, CreateIncidentPayload, CreateSimpleIncidentPayload, GetIncident, Incident, IncidentListRecord, createIncident, deleteIncidentsApi, getIncidentsApi } from "../api/incidents.api";
import { POLLING_TIMER_MS } from '../constants'
import { getPeriodTimes } from "../utils/date";
import { CLngLat } from "../components/Map/Map";

export type IncidentSelectedNode = { id: number | string | null }

export interface IncidentState {
    loadingLabel: string;
    loading: boolean;
    incidentModalIsOpen: boolean;
    incidents: GetIncident[];
    liveIncidents: GetIncident[];
    viewIncidents: GetIncident[],
    selectedNode: IncidentSelectedNode | null;
    incidentLngLat: CLngLat;
    incidentPagination:{total:number, page:number, pageSize:number}
}

const initialState: IncidentState = {
    loadingLabel: "",
    loading: false,
    incidentModalIsOpen: false,
    incidents: [],
    viewIncidents: [],
    liveIncidents: [],
    selectedNode: null,
    incidentLngLat: [0, 0],
    incidentPagination: {
        total: 0,
        page: 0,
        pageSize: 0
    }
};


export const getLiveIncidentsAction = createAsyncThunk(
    "incidents/getLiveIncidents",
    async () => {
        // todo add arg for query param
        const currentDate = new Date();

        // Set start of the day
        const date_from = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(),
            currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds(),
            currentDate.getMilliseconds() - POLLING_TIMER_MS).toISOString().slice(0, -5);
        const date_to = new Date().toISOString().slice(0, -5);

        const params = {
            date_from,
            date_to,
            page: 1,
            page_size:10
        }
        const incidents:any = await getIncidentsApi(params)
        if (incidents.length > 1) {
            return incidents?.incidents.slice(-1)
        }
        return incidents?.incidents
    }
);

export const getIncidentsAction = createAsyncThunk(
    "incidents/getIncidents",
    async (payload: { page: number, pageSize: number }) => {
        // const { from: date_from, to: date_to } = getPeriodTimes(2)
        const params = {
            page:payload.page,
            page_size: payload.pageSize
        }
        const response: any = await getIncidentsApi(params)
        
        const incidents = response?.incidents?.map((incident: any) => {
            return {
                ...incident, ...incident.properties,
            }
        })

        return {
            incidents,  
            incidentPagination: { total: response.total, page: response.page, pageSize: response.page_size }
        };
    }
);
export const createIncidentAction = createAsyncThunk(
    "incidents/createIncident",
    async (payload: CreateSimpleIncidentPayload | CreateCustomIncidentPayload) => {
        const incident = await createIncident(payload)
        console.log('crateed incident res', incident)
        return incident;
    }
);

export const deleteIncidents = createAsyncThunk(
    "incidents/deleteIncidents",
    async (payload: any) => {
        const incidents = await deleteIncidentsApi(payload)
        return payload
    }
);

export const incidentsSlice = createSlice({
    name: "incidents",
    initialState,
    reducers: {
        toggleIncidentModal: (state, { payload }: { payload: boolean }) => {
            return { ...state, incidentModalIsOpen: payload }
        },
        selectIncidentNode: (state, { payload }: { payload: IncidentSelectedNode | null }) => {
            console.log('Selected node incident', payload)
            return { ...state, selectedNode: payload }
        },
        toggleNewIncidentModalIsOpen: (state, { payload }) => {
            return { ...state, incidentModalIsOpen: payload };
        },
        addViewIncidents: (state, { payload: viewIncident }) => {
            return { ...state, viewIncidents: [viewIncident] };
        },
        removeViewIncidents: (state) => {
            return { ...state, viewIncidents: [] };
        },
        setLngLat: (state, { payload }) => {
            console.log("set lnglat ", payload)
            return { ...state, incidentLngLat: payload }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getIncidentsAction.pending, (state) => {
                return { ...state, loading: true };
            })
            .addCase(getIncidentsAction.fulfilled, (state, { payload }) => {
                return { ...state, incidents: payload.incidents, loading: false, incidentPagination:payload.incidentPagination };
            })
            .addCase(getLiveIncidentsAction.pending, (state) => {
                return { ...state, loading: true };
            })
            .addCase(getLiveIncidentsAction.fulfilled, (state, { payload: liveIncidents }) => {
                return { ...state, liveIncidents, loading: false };
            })
            .addCase(createIncidentAction.pending, (state, { payload }) => {
                return { ...state, loading: true, loadingLabel: 'Creating Incident' };
            })
            .addCase(createIncidentAction.fulfilled, (state, { payload: incident }) => {
                return { ...state, loading: false, loadingLabel: '', incidentModalIsOpen: false, selectedNode: null };
            })
            .addCase(deleteIncidents.pending, (state, { payload }) => {
                return { ...state, loading: true, loadingLabel: '' };
            })
            .addCase(deleteIncidents.fulfilled, (state, { payload }) => {
                if (payload.all === true) return { ...state, loading: false, loadingLabel: '', incidents:[] }
        
                const incidents = state.incidents.filter(i=> !payload.ids.includes(i._id))
                return { ...state, loading: false, incidents };
            })
    },
});

// Action creators are generated for each case reducer function
export const { selectIncidentNode, toggleIncidentModal, setLngLat, addViewIncidents, removeViewIncidents } =
    incidentsSlice.actions;

export default incidentsSlice.reducer;
