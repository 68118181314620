import React from "react";
import * as Ant from "antd";
import styled from "styled-components";
import { StyleVariables } from "../../styles/variables";

const Spinner = styled(Ant.Spin)`
  &&& {
    color: ${StyleVariables.primaryColor};
  }
`;

const GlobalContainerLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;
const Loader = styled.div`
  color: ${StyleVariables.primaryColor};
  background-color: ${StyleVariables.primaryBgRGBA};
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

//@ts-ignore
const antIcon = <Ant.Icon type='loading' style={{ fontSize: 24 }} spin />;

const GlobalLoader: React.FC<{ title: string }> = (props) => (
    <GlobalContainerLoader>
        <Loader>
            <div style={{ marginBottom: "10px" }}>{props.title}</div>{" "}
            <Spinner indicator={antIcon} />
        </Loader>
    </GlobalContainerLoader>
);

export default GlobalLoader;
