import BoardingImage from '../../images/boarding.png'

import HospitalImage from '../../images/pins/hospital-pin.png';
import MetroImage from '../../images/pins/metro-pin.png';
import ElectricityImage from '../../images/pins/electricity-pin.png';
import TollsImage from '../../images/pins/tolls-pin.png';
import BusImage from '../../images/pins/bus-pin.png';
import TrainSignalImage from '../../images/pins/train-signal-pin.png';
import infoImage from '../../images/info.png';
import exclamationImage from '../../images/exclamation.png';



import TrainService from '../../images/pins/train-service.png'
import RoadOperations from '../../images/pins/road-operations.png'
import MetroStations from '../../images/pins/metro-stations.png'
import MetroOperations from '../../images/pins/metro-operations.png'
import MetroEvacuation from '../../images/pins/metro-evacuation.png'
import AirportTransportation from '../../images/pins/airport-transportation.png'
import PassengerInspection from '../../images/pins/passenger-inspection.png'
import FlightInformation from '../../images/pins/flight-information.png'
import AdminWorkstation from '../../images/pins/admin-workstation.png'
import BuildingManagement from '../../images/pins/building-management.png'
import InformationSystem from '../../images/pins/information-system.png'
import BoardingControl from '../../images/pins/boarding-control.png'
import AirportEvacuation from '../../images/pins/airport-evacuation.png'
import RoadNodeImage from '../../images/pins/road-node.png'

import CallCenterImage from '../../images/pins/call-center.png'
import BoardingServiceImage from '../../images/pins/boarding.png'
import CheckInImage from '../../images/pins/check-ing.png'



export function GetImageFromString(imgName: string): string {
    if (imgName === 'metro') return MetroImage
    if (imgName === 'boarding') return BoardingImage
    if (imgName === 'boarding') return BoardingImage
    if (imgName === 'hospital') return HospitalImage
    if (imgName === 'train-signal') return TrainSignalImage
    if (imgName === 'tolls') return TollsImage
    if (imgName === 'electricity') return ElectricityImage
    if (imgName === 'bus') return BusImage

    if (imgName === 'train-service') return TrainService
    if (imgName === 'road-operations') return RoadOperations
    if (imgName === 'metro-stations') return MetroStations
    if (imgName === 'metro-operations') return MetroOperations
    if (imgName === 'metro-evacuation') return MetroEvacuation
    if (imgName === 'airport-transportation') return AirportTransportation
    if (imgName === 'passenger-inspection') return PassengerInspection
    if (imgName === 'flight-information') return FlightInformation
    if (imgName === 'admin-workstation') return AdminWorkstation
    if (imgName === 'building-management') return BuildingManagement
    if (imgName === 'information-system') return InformationSystem
    if (imgName === 'boarding-control') return BoardingControl
    if (imgName === 'airport-evacuation') return AirportEvacuation
    if (imgName === 'info') return infoImage
    if (imgName === 'exclamation') return exclamationImage
    if (imgName === 'road-node') return RoadNodeImage

    if (imgName === 'call-center') return CallCenterImage
    if (imgName === 'boarding-service') return BoardingServiceImage
    if (imgName === 'checkin') return CheckInImage


    return CheckInImage
}