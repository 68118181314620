import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

const useGetRealTimeData = () => {
  const { data, loading, lastSync } = useSelector(
    (state: any) => state.realTime
  );

  useEffect(() => { }, [loading, data, lastSync]);

  return [loading, data, lastSync];
};

export default useGetRealTimeData;
