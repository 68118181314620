import React, { useEffect, useState } from "react";
import { FormContainer } from "../ui/Forms";
import { StyleVariables } from "../../styles/variables";
import Row from "antd/lib/row";
import Col from "antd/lib/grid/col";
import { Button, InputText, Option, Select, TextArea } from "../ui/components";
import styled from "styled-components";
import { getRandomHash } from "../Simulations-form/Trigger-form";
import { useDispatch, useSelector } from "react-redux";
import { _ALL_NODES } from "../../data/triggers/triggers";
import { Trigger } from "../../models/trigger";
import { StoreState } from "../../redux/state";
import {
  IncidentSelectedNode,
  createIncidentAction,
  toggleIncidentModal,
} from "../../redux/incidentsSlice";
import {
  CreateCustomIncidentPayload,
} from "../../api/incidents.api";
import { Checkbox, Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import {
  INCIDENT_PRIORITIES,
  INCIDENT_REPORT_RECIPIENTS,
  INCIDENT_SCALE_OF_IMPACTS,
  INCIDENT_SEVERITIES,
} from "./incident-data";
import { AppStateType } from "../../models/app";

interface FormProps extends FormComponentProps { }

const FormItem = styled.div`
  margin-bottom: 15px;
`;

const nodes: Trigger[] = _ALL_NODES;

export const _IncidentForm: React.FC<FormProps> = ({ form }) => {
  const dispatch = useDispatch();
  const { getFieldDecorator } = form;

  let i = 0;
  const [selectedNode, setSelectedNode] = useState(null)

  const $selectedIncidentNode = useSelector<StoreState, IncidentSelectedNode>(
    (state) => state.incidents.selectedNode as IncidentSelectedNode
  );

  const $lnglatIncident = useSelector<StoreState, any>(
    (state) => state.incidents.incidentLngLat
  );
  const $activeAppState = useSelector<StoreState, AppStateType>(
    (state) => state.app.activeState
  );

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    form.validateFields((err: any, incidentData: FormProps) => {
      console.log("incidentData", incidentData);
      if (!err) {
        reportIncident(incidentData as any);
      }
    });
  };
  useEffect(() => {
    console.log("$selectedIncidentNode.id", $selectedIncidentNode);
    const node = nodes.find((tr) => tr.id == $selectedIncidentNode!?.id)
    if (node) {
      setSelectedNode(node?.id as any);
    } else {
      setSelectedNode(null);
    }

  }, [$selectedIncidentNode]);
  const resetForm = () => {
    form.resetFields();
  };

  const reportIncident = ({
    title,
    description,
    nodeId,
    report,
    event, incident_priority, incident_severity, scale_of_impacts, report_recipients
  }: any
    //   {
    // title: string;
    // description: string;
    // report: boolean;
    // nodeId:number;
    //   }
  ) => {
    let incident!: CreateCustomIncidentPayload;
    incident = {
      type: "Feature",
      properties: {
        event: event,
        title, incident_priority, incident_severity, scale_of_impacts, report_recipients
      },
      report:false,
    } as CreateCustomIncidentPayload;

    // incident created by clicking anywhere on map
    if ($activeAppState === "manual-incident") {
      const dist = 0.001;
      incident["properties"]["geometry"] = {
        type: "Polygon",
        coordinates: [
          [$lnglatIncident[0] - dist, $lnglatIncident[1] - dist],
          [$lnglatIncident[0] - dist, $lnglatIncident[1] + dist],
          [$lnglatIncident[0] + dist, $lnglatIncident[1] - dist],
          [$lnglatIncident[0] + dist, $lnglatIncident[1] + dist],
        ],
      };
    }
    else {
      incident["properties"]["node"] = nodeId
    }
      form.resetFields();
    dispatch(createIncidentAction(incident));
    dispatch(toggleIncidentModal(false));
  };
  const titleChange = (e: any) => {
    console.log(e);
  };
  const descriptionChange = (e: any) => {
    console.log(e);
  };

  const handleTriggerFormChange = (changedFields: { trigger: any }) => {
    console.log("Changed Fields", changedFields);
    form.setFields({ trigger: changedFields });
    form.getFieldsValue();
    console.log("Changed Fields form.getFieldsValue", form.getFieldsValue());
  };
  return (
    <FormContainer>
      <Row gutter={16} style={{ color: StyleVariables.primaryColor }}>
        <Col span={24}>{++i}. Please write a title for the incident</Col>
        <Col span={24}>
          <FormItem>
            <Form.Item>
              {getFieldDecorator("title", {
                rules: [{ required: true, message: "Please select a title!" }],
              })(
                //@ts-ignore
                <InputText />
              )}
            </Form.Item>
          </FormItem>
        </Col>
        <Col span={24}>{++i}. Please write a description</Col>
        <Col span={24}>
          <FormItem>
            <Form.Item>
              {getFieldDecorator("description", {
                rules: [
                  { required: true, message: "Please write a description" },
                ],
              })(
                //@ts-ignore
                <TextArea />
              )}
            </Form.Item>
          </FormItem>
        </Col>
        <Col span={24}>{++i}. Fill the rest incident information</Col>
        <Col span={8}>
          <Form.Item>
            {getFieldDecorator("nodeId", {
              initialValue: selectedNode,
              rules: [{ required: true, message: "Please select a node!" }],
            })(
              //@ts-ignore
              <Select
                placeholder='Select a Node'
                showSearch={true}
                filterOption={(input: any, option: any) =>
                  (option?.props?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {nodes.map((node) => (
                  <Option key={getRandomHash(5)} value={node.id}>
                    {node.label}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item>
            {getFieldDecorator("event", {
              rules: [
                { required: true, message: "Please write an event type!" },
              ],
            })(
              //@ts-ignore
              <InputText placeholder='Event type' />
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item>
            {getFieldDecorator("incident_severity", {
              rules: [{ required: true, message: "Please select a severity!" }],
            })(
              //@ts-ignore
              <Select placeholder='Severity' showSearch={true}>
                {INCIDENT_SEVERITIES.map((node) => (
                  <Option key={getRandomHash(5)} value={node}>
                    {node}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item>
            {getFieldDecorator("incident_priority", {
              rules: [
                { required: true, message: "Please select a periority!" },
              ],
            })(
              //@ts-ignore
              <Select placeholder='Priority' showSearch={true}>
                {INCIDENT_PRIORITIES.map((node) => (
                  <Option key={getRandomHash(5)} value={node.value}>
                    {node.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item>
            {getFieldDecorator("scale_of_impacts", {
              rules: [{ required: true, message: "Please select an impact!" }],
            })(
              //@ts-ignore
              <Select placeholder='Scale Of Impact' showSearch={true}>
                {INCIDENT_SCALE_OF_IMPACTS.map((node) => (
                  <Option key={getRandomHash(5)} value={node}>
                    {node}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item>
            {getFieldDecorator("report_recipients", {
              rules: [{ required: true, message: "Please select recepients!" }],
            })(
              //@ts-ignore
              <Select placeholder='Recipients' mode='multiple'>
                {INCIDENT_REPORT_RECIPIENTS.map((node) => (
                  <Option key={getRandomHash(5)} value={node}>
                    {node}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>

        <Col span={24}>
          <div className='flex c-checkbox'>
            {++i}. Select if you want to Report to coordinator center.
            <Form.Item>
              {getFieldDecorator("report", {
                valuePropName: "report",
                initialValue: false,
              })(<Checkbox></Checkbox>)}
            </Form.Item>
          </div>
        </Col>
        <Col span={8}>
          <p>&nbsp;</p>
          <Button rtype='primary' htmlType='submit' onClick={handleSubmit}>
            Report Incident
          </Button>
        </Col>
      </Row>
    </FormContainer>
  );
};

export const IncidentsForm = Form.create({ name: "incident_form" })(
  _IncidentForm
);
