import { DeleteOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Tooltip, Popconfirm, message } from "antd";
import React, { useState } from "react";
import { ReactComponent as AddCircle } from "../../images/flaticon/add-circle.svg";
import { useDispatch } from "react-redux";
import { deleteIncidents, getIncidentsAction, toggleIncidentModal } from "../../redux/incidentsSlice";
import { setAppState } from "../../redux/appSlice";
import {
  addTriggerRedux,
  resetModal,
  toggleNewSimulationModal,
  resetSimulations,
} from "../../redux/simulationsSlice";



type FormProps = { incidentsSelectedRows:string[] };
export const IncidentsHeaderTab: React.FC<FormProps> = ({
  incidentsSelectedRows,
}) => {
  const dispatch = useDispatch();

  const [confirmText, setText] = useState<string>("");
  const [simText, setSimText] = useState<string>("");

    const onClickStartSim = () => {
      let triggers: { nodeId: string; triggerName: string }[] = [];
      incidentsSelectedRows.map((inc: any) => {
        if (inc?.node > 100) {
          return;
        }
        triggers.push({ nodeId: inc?.node, triggerName: inc?.event });
      });

      dispatch(addTriggerRedux(triggers));
      dispatch(setAppState("simulation"));
      dispatch(resetModal({ resetModal: false }));
      //@ts-ignore
      dispatch(resetSimulations());
      dispatch(toggleNewSimulationModal(true));
      dispatch(toggleIncidentModal(false));
    };
  const deleteIncidentsFunc = () => {
    if (incidentsSelectedRows.length === 0) {
      dispatch(deleteIncidents({ all: true, ids: [] }));
    } else {
      dispatch(
        deleteIncidents({
          all: false,
          ids: incidentsSelectedRows.map((i: any) => i._id),
        })
      );
      setTimeout(() => {
        dispatch(getIncidentsAction({ page: 1, pageSize: 5 }));
      }, 100);
    }
  };

  const deleteText = () => {
    let text = "";
    if (incidentsSelectedRows.length === 0) {
      text = "Are you sure you want to delete all incidents?";
    } else {
      text = `Are you sure you want to delete ${incidentsSelectedRows.length} selected incidents?`;
    }
    setText(text);
  };

    const startSimText = () => {
      let text = "";
      if (incidentsSelectedRows?.every((i: any) => i?.node < 100)) {
        text = "Are you sure you want to start simulation for these incidents?";
      } else {
        text = `Some incidents don't support simulation. Start with the rest?`;
      }
      setSimText(text);
    };

  return (
    <div className='card-title-container'>
      <span className='card-title'>Incidents</span>
      <div style={{ transform: "translateY(2px)", marginRight: "5px" }}>
        {incidentsSelectedRows?.some((i: any) => i?.node < 100) && (
          <Tooltip title='Start simulation' placement='bottom'>
            <Popconfirm
              placement='topLeft'
              title={simText}
              onConfirm={onClickStartSim}
              okText='Yes'
              cancelText='No'
            >
              <a
                onClick={startSimText}
                style={{
                  color: "#bfbfbf",
                  marginRight: "10px",
                  fontSize: "17px",
                }}
              >
                <MenuFoldOutlined style={{ transform: "translateY(-2px)" }} />
              </a>
            </Popconfirm>
          </Tooltip>
        )}
        <Tooltip title='Create manual incident'>
          <span className='add-anchor'>
            <a onClick={() => {
              dispatch(setAppState("manual-incident"));
              message.info("Click on map to create incident");
            }}>
              <AddCircle className='add-circle' />
            </a>
          </span>
        </Tooltip>
        <span className='add-anchor'>
          <Popconfirm
            placement='topLeft'
            title={confirmText}
            onConfirm={deleteIncidentsFunc}
            okText='Yes'
            cancelText='No'
          >
            <a
              onClick={deleteText}
              style={{
                color: "#bfbfbf",
                marginLeft: "10px",
                fontSize: "18px",
              }}
            >
              <DeleteOutlined style={{ transform: "translateY(-2px)" }} />
            </a>
          </Popconfirm>
        </span>
      </div>
    </div>
  );
};