// const MAPBOX_TOKEN = process.env.MapboxAccessToken; // eslint-disable-line
export const DEFAULT_USER_ID = "5f5cabdc-6ed3-4313-b5de-2c9ab4b3781a"
export const MAPBOX_TOKEN =
  "pk.eyJ1IjoidGFzb3NrYWtvdXJpcyIsImEiOiJjbGRzb3pvdXcwcHpuM3JrZzlleGx6anhqIn0.1tOYjsJoF7Xjs15QSsOx-A";
export const POLLING_TIMER_MS = 10000;
// API
const API_URL = `${window.location.href}api` || "https://precinct-dev.konnecta.io/api";
const SIMULATIONS_URL = `${API_URL}/connector`;
export const REAL_TIME_API = `${API_URL}/real-time/export_states/ui`;

export const SIMULATIONS_API = `${SIMULATIONS_URL}/simulation/`;

export const GET_EXECUTIONS_API = `${SIMULATIONS_URL}/executions`;
export const CREATE_EXECUTION_API = `${SIMULATIONS_URL}/execution`;
export const DELETE_EXECUTIONS = `${SIMULATIONS_URL}/executions`;

export const GET_INCIDENTS = `${SIMULATIONS_URL}/incidents`;
export const CREATE_INCIDENT = `${SIMULATIONS_URL}/incident`;
export const REPORT_INCIDENT = `${SIMULATIONS_URL}/report`;

export const MITIGATION_ACTIONS = `${SIMULATIONS_URL}/mitigation/actions`;

export const GET_RESILIENCE = `${SIMULATIONS_URL}/resilience`;

export const getAccessToken = () => {
  let oidc: any = sessionStorage.getItem("oidc.default")
  if (!oidc) {
    return undefined
  }

  oidc = JSON.parse(oidc)
  const accessToken:string = oidc?.tokens?.accessToken;
  const expiresAt: number = oidc?.tokens?.expiresAt;

  // *1000 because we want it in ms
  const targetTime = new Date(expiresAt*1000);
  const currentTime = new Date();
  if (currentTime >= targetTime) {
    console.log("Token has expired")
    return undefined
  }
  return accessToken

}


// . Get All incidents[GET]
// http://localhost:8000/incidents
//POST: http://localhost:8000/incident

// GET by id - http://localhost:8000/incident/1b14a760-c35f-4f84-a702-b2ae95f5dc32
