import React from "react";
import "./index.scss";
import App from "./App/App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import "antd/dist/antd.css";
import store from "./redux/store";
import 'mapbox-gl/dist/mapbox-gl.css';
import { createRoot } from 'react-dom/client';
import { OidcProvider } from "@axa-fr/react-oidc";

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement); // createRoot(container!) if you use TypeScript

export const KEYCLOAK_CONFIG = {
  keycloakUrl: "https://ids.konnecta.io",
  realm: "realm_precinct",
  clientId: "precinct_pcke",
};
const configuration = {
  client_id: KEYCLOAK_CONFIG.clientId,
  redirect_uri: `${window.location.origin}/auth/keycloak`,
  scope: "openid profile email offline_access", // offline_access scope allow your client to retrieve the refresh_token
  authority: KEYCLOAK_CONFIG.keycloakUrl,
  authority_configuration: {
    issuer: `${KEYCLOAK_CONFIG.keycloakUrl}/realms/${KEYCLOAK_CONFIG.realm}`,
    revocation_endpoint: `${KEYCLOAK_CONFIG.keycloakUrl}/realms/${KEYCLOAK_CONFIG.realm}/protocol/openid-connect/revoke`,
    authorization_endpoint: `${KEYCLOAK_CONFIG.keycloakUrl}/realms/${KEYCLOAK_CONFIG.realm}/protocol/openid-connect/auth`,
    token_endpoint: `${KEYCLOAK_CONFIG.keycloakUrl}/realms/${KEYCLOAK_CONFIG.realm}/protocol/openid-connect/token`,
    userinfo_endpoint: `${KEYCLOAK_CONFIG.keycloakUrl}/realms/${KEYCLOAK_CONFIG.realm}/protocol/openid-connect/userinfo`,
    end_session_endpoint: `${window.location.origin}/login`,
    check_session_iframe: `${KEYCLOAK_CONFIG.keycloakUrl}/realms/${KEYCLOAK_CONFIG.realm}/protocol/openid-connect/login-status-iframe.html`,
  },
  // token_request_extras: {
  //   client_secret: "**********",
  // },
};

root.render(
  <OidcProvider configuration={configuration}>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </OidcProvider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
