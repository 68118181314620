import React, { useState } from "react";
import { Option, Select } from "../ui/components";

type Props = {
  prop: string[];
  setProp: any;
  possibleValues: string[]
};

export const TagComp: React.FC<Props> = React.memo(({ prop, setProp, possibleValues }) => {

  function handleChange(value: any) {
    setProp(value)
  }
  return (
    <>
      {/* @ts-ignore */}
      <Select
        mode='multiple'
        size='small'
        value={prop}
        placeholder='Please select'
        onChange={handleChange}
      >
        {possibleValues.map((val: string, index: number) => (
          <Option key={index} value={val}>{val}</Option>
        ))}
      </Select>
    </>
  );
}
);