import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { setAppState } from "../../redux/appSlice";
import { selectIncidentNode, toggleIncidentModal } from "../../redux/incidentsSlice";
import { resetModal, resetSimulations, selectNode } from "../../redux/simulationsSlice";
import { randomString } from "../../utils/randomString";
import { Tooltip } from "antd";
export type PopupProps = {
    props: { [key: string]: any } | null,
    showActions: boolean
}
function filterProps(props: { [key: string]: any }) {
    let visibleProps = props["visibleProps"];
    if (visibleProps === undefined) {
      return props;
    }
    
    let updatedProps: any = {};
    if (!Array.isArray(visibleProps)) {
        visibleProps = JSON.parse(visibleProps)
    }

    //@ts-ignore
    visibleProps.map((k: string) => {
        updatedProps[k] = props[k] != undefined ? props[k] : "Not available";
    });
    updatedProps.title = props.title;
    
    return updatedProps
}




export const PopupContent: React.FC<PopupProps> = ({ props = {}, showActions }) => {
    const dispatch = useDispatch()
    const reportIncident = (id: number) => {
        dispatch(setAppState('incidents'))
        dispatch(selectIncidentNode({ id }))
        dispatch(toggleIncidentModal(true))
    }
    const startSim = (id: number): void => {
      dispatch(setAppState("simulation"));

      //@ts-ignore
      dispatch(resetSimulations());
      dispatch(selectNode({ nodeId: id }));
      dispatch(resetModal({ resetModal: false }));
    }
    const actions = [
        {
            label: "Report Incident",
            action: () => {
                reportIncident(props!.id);
            },
        },
        {
            label: "Simulate Event",
            action: () => {
                startSim(props!.id);
            },
        },
    ];
    const [filteredProps, setFilteredProps] = useState<{ [key: string]: any }>({})

    useEffect(() => {
        setFilteredProps(filterProps(props!))
    }, [props])
    useEffect(() => {
    }, [showActions])
    return (
        <div>
            <div className="popup-title">{filteredProps.title || 'N/A'}</div>
            <div className="popup-body">
                {
                    Object.keys(filteredProps).map((key) => {
                        if (key.toLowerCase() === "title") {
                            return
                        }
                        return (
                          <div
                            className='popup-body-child'
                            key={randomString(6)}
                          >
                            <div>{capitalizeFirstLetter(key)}</div>
                            <Tooltip title={`${filteredProps[key]}`}>
                              <div>{filteredProps[key]}</div>
                            </Tooltip>
                          </div>
                        );
                    })
                }
                {showActions ?
                    <div className="popup-btn-container">
                        {
                            actions.map(action => {
                                if (props!.id >= 100 && action.label === "Simulate Event") {
                                    return
                                }
                                return <div className="popup-btn" onClick={action.action} key={randomString(6)}>{action.label}</div>
                            })
                        }

                    </div>
                    : <div className="popup-btn-show-more">Click marker for more actions</div>
                }
            </div>
        </div>
    )
}
function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}