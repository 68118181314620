import React from "react";
import * as Ant from "antd";
import styled from "styled-components";
import { StyleVariables } from "../../styles/variables";

export const Spinner = styled(Ant.Spin)`
  &&& {
    color: ${StyleVariables.primaryColor};
  }
`;
//@ts-ignore
const antIcon = <Ant.Icon type='loading' style={{ fontSize: 24 }} spin />;

const Loader: React.FC = () => <Spinner indicator={antIcon} />;

export default Loader;