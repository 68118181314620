import * as React from "react";
import Map, { useMap, Layer, LayerProps, Marker, Source } from "react-map-gl";
import { MAPBOX_TOKEN } from "../../constants";
import { StoreState } from "../../redux/state";
import { GetImageFromString } from "../New-Datasets/image-selector";
import {
  Feature,
  GeoJsonProperties,
  Geometry,
  MultiLineString,
  Point,
  Polygon,
} from "geojson";
import { useState, useRef, useCallback } from "react";
import mapboxgl, { LngLat, MapboxEvent } from "mapbox-gl";
import { FlyTo } from "../../utils/map";
import { PopupContent } from "../New-Datasets/Popup-Content";
import { GetIncident } from "../../api/incidents.api";
import {
  addViewIncidents,
  setLngLat,
  toggleIncidentModal,
} from "../../redux/incidentsSlice";
import { IncidentEx } from "./incident.example";
import { setAppState } from "../../redux/appSlice";
import { useDispatch, useSelector } from "react-redux";
import { StyleVariables } from "../../styles/variables";
import { AppStateType } from "../../models/app";

type MapClickEvent = MapboxEvent<MouseEvent>;
type MapMoveEvent = MapboxEvent<MouseEvent>["originalEvent"];
type PosX = number;
type PosY = number;
type Lng = number;
type Pinned = boolean;
type Lat = number;
type Zoom = number;
export type CLngLat = [Lng, Lat];
type PopupProps = { [key: string]: any };
type PopupState = [PosX, PosY, Pinned, Lng, Lat, PopupProps];
type ContextState = {
  lngLat: CLngLat;
  setContext?: any;
};

type CommonMouseEvents = {
  mouseClick: (e: MapClickEvent, props: PopupProps) => void;
  mouseMove: (e: MapMoveEvent, props: PopupProps) => void;
};

type NodeProps<G extends Geometry = Geometry> = CommonMouseEvents & Feature<G>;

const INITIAL_POPUP_XY = [-500, -500];

const DEFAULT_CONTEXT: ContextState = { lngLat: [0, 0] };

export const ExtraMap: React.FC = React.memo(() => {
  const mapRef = useRef<mapboxgl.Map>();

  const dispatch = useDispatch();

  const $activeAppState = useSelector<StoreState, AppStateType>(
    (state) => state.app.activeState
  );
  const [zoom, setZoom] = useState<[number, number[]]>([0, []]);
  const [xy, setXY] = useState<PopupState | null>(null);
  const [context, setContext] = useState<ContextState>(DEFAULT_CONTEXT);

  const incidents = useSelector<StoreState, GetIncident[]>(
    (state) => state.incidents.viewIncidents
  );

  React.useEffect(() => {
    if (incidents && incidents.length === 0) return;
    console.log("incidents", incidents);
    const point = incidents[0].geometry.coordinates[0][0] as [number, number];

    setTimeout(() => {
      FlyTo(point, 15, mapRef.current);
    }, 150);
  }, [incidents]);

  const mouseClick: CommonMouseEvents["mouseClick"] = useCallback(
    (e, props) => {
      e.originalEvent.stopPropagation();
      const { lng, lat } = (e as any)!.lngLat || (e as any).target._lngLat;
      const { clientX: x, clientY: y } = e.originalEvent;
      setXY([x, y, true, lng, lat, props]);
    },
    []
  );
  const mouseMove: CommonMouseEvents["mouseMove"] = useCallback(
    (e: MapMoveEvent, props) => {
      e.stopPropagation();
      const { clientX: x, clientY: y } = e;
      setXY((prev) => {
        if (!prev) return [x, y, false, 0, 0, props];
        const [px, py, pinned, lng, lat, PrevProps] = prev;
        if (pinned) return [px, py, pinned, lng, lat, PrevProps];
        return [x, y, false, 0, 0, props];
      });
    },
    []
  );

  return (
    <Map
      style={{ width: "100vw", height: "100vh" }}
      onMouseMove={(e) => {
        setXY((prev) => {
          if (!prev) return prev;
          const [px, py, pinned, lng, lat, props] = prev;
          if (pinned) return [px, py, pinned, lng, lat, props];
          return null;
        });
      }}
      onClick={(e:any) => {

        if ($activeAppState === "manual-incident") {
          const { lngLat } = e;
          console.log([lngLat?.lng, lngLat?.lat], lngLat.lng);
          dispatch(setLngLat([lngLat?.lng, lngLat?.lat]));
          dispatch(toggleIncidentModal(true));
        }
      

        setXY(null);
        setContext(DEFAULT_CONTEXT);
      }}
      onContextMenu={(e) => {
        const { point, lngLat } = e;
        setContext({ lngLat: [lngLat.lng, lngLat.lat] });
      }}
      onMove={(e) => {
        const bounds = mapRef!.current!.getBounds().toArray().flat();

        const zoom = mapRef!.current!.getZoom();
        setZoom([zoom, bounds]);
        setXY((prev) => {
          if (!prev) return prev;
          const [, , pinned, lng, lat, props] = prev;
          if (pinned) {
            const coordinate = mapRef!.current!.project([lng, lat]);
            const { x, y } = coordinate;

            return [x, y, pinned, lng, lat, props];
          }
          return prev;
        });
      }}
      onZoom={(zoom) => {
        const bounds = mapRef.current
          ? mapRef.current.getBounds().toArray().flat()
          : null;
        setZoom([zoom.viewState.zoom, bounds!]);
        setXY((prev) => {
          if (!prev) return prev;

          const [, , pinned, lng, lat, props] = prev;
          if (pinned) {
            const coordinate = mapRef!.current!.project([lng, lat]);
            const { x, y } = coordinate;
            return [x, y, pinned, lng, lat, props];
          }
          return prev;
        });
      }}
      onLoad={(event) => {
        mapRef.current = event.target;
      }}
      mapboxAccessToken={MAPBOX_TOKEN}
      projection={"globe"}
      initialViewState={{
        longitude: 23.77,
        latitude: 38.01,
        zoom: 10.8,
        // pitch: 56,
        // bearing: -15.25,
      }}
      fog={{
        color: "rgb(186, 210, 235)", // Lower atmosphere
        //@ts-ignore
        "high-color": "rgb(36, 92, 223)", // Upper atmosphere
        "horizon-blend": 0.02, // Atmosphere thickness (default 0.2 at low zooms)
        "space-color": "rgb(11, 11, 25)", // Background color
        "star-intensity": 0.6, // Background star brightness (default 0.35 at low zoooms )
      }}
      mapStyle='mapbox://styles/tasoskakouris/cli65i7z802qg01pgdz6adwtt'
      // mapStyle='mapbox://styles/mapbox/streets-v12'
    >
      {/* : (zoom > 11 ? 11 : zoom) */}
      <ContextMenu {...{ lngLat: context.lngLat, setContext: setContext }} />
      <DataComp {...{ mouseMove, mouseClick, zoom }} />
      <IncidentsData {...{ mouseMove, mouseClick }} incidents={incidents} />
      <PopupTest xy={xy}>
        <PopupContent
          props={!xy ? {} : xy[5]}
          showActions={!xy ? false : xy[2]}
        />
      </PopupTest>
    </Map>
  );
});
export const ContextMenu: React.FC<ContextState> = ({ lngLat, setContext }) => {
  const dispatch = useDispatch();
  const { current: map } = useMap();

  const reportIncident = (lngLat: CLngLat) => {
    dispatch(setAppState("manual-incident"));
    dispatch(setLngLat(lngLat));
    dispatch(toggleIncidentModal(true));
    setContext(DEFAULT_CONTEXT);
  };

  const coordinate = map!.project([lngLat[0], lngLat[1]]);
  const { x, y } = coordinate;
  return (
    <div
      onClick={() => {
        reportIncident(lngLat);
      }}
      style={{
        cursor: "pointer",
        position: "absolute",
        top: `${y}px`,
        left: `${x}px`,
        width: "100px",
        height: "26px",
        zIndex: 999,
        background: StyleVariables.primaryBg,
        color: "white",
      }}
    >
      <div
        style={{
          marginLeft: "7px",
          marginTop: "3px",
        }}
      >
        Create Incident
      </div>
    </div>
  );
};
export const IncidentsData = React.memo(
  ({
    mouseMove,
    mouseClick,
    incidents,
  }: CommonMouseEvents & { incidents: GetIncident[] }) => {
    return (
      <>
        {incidents
          .map((incident) => ({
            ...incident,
            geometry: {
              ...incident.geometry,
              type: "Point",
              coordinates: incident.geometry.coordinates[0][0],
            },
            properties: {
              ...incident.properties,
              customRendering: true,
              icon: "exclamation",
              type: "image-marker",
              id: "incident-1",
            },
          }))
          .map((node, index) => (
            <MapNodeSelector
              key={index}
              {...node}
              mouseClick={mouseClick}
              mouseMove={mouseMove}
              classes={["incident-marker"]}
            />
          ))}
      </>
    );
  }
);

export const DataComp = React.memo(
  ({
    mouseMove,
    mouseClick,
    zoom,
  }: CommonMouseEvents & { zoom: [number, number[]] }) => {
    function filterProps(
      geoJsonLayers: Feature<Geometry, GeoJsonProperties>[],
      filtering: any
    ) {
      const { opLevel, type, operator } = filtering;
      let filteredGeoJsonLayers: Feature<Geometry, GeoJsonProperties>[] =
        geoJsonLayers;

      if (opLevel.length !== 0) {
        filteredGeoJsonLayers = filteredGeoJsonLayers.filter((i: any) =>
          opLevel.some((j: any) =>
            i?.properties?.["Op. Level"]
              ?.toLowerCase()
              ?.includes(j?.toLowerCase())
          )
        );
      }
      if (type.length !== 0) {
        filteredGeoJsonLayers = filteredGeoJsonLayers.filter((i: any) =>
          type.some((j: any) =>
            i?.properties?.Type?.toLowerCase()?.includes(j?.toLowerCase())
          )
        );
      }
      if (operator.length !== 0) {
        filteredGeoJsonLayers = filteredGeoJsonLayers.filter((i: any) =>
          operator.some((j: any) =>
            i?.properties?.Operator?.toLowerCase()?.includes(j?.toLowerCase())
          )
        );
      }
      return filteredGeoJsonLayers;
    }

    let geoJsonLayers = useSelector<
      StoreState,
      Feature<Geometry, GeoJsonProperties>[]
    >((state) => state.app.geoJsonLayers.map((item) => item.features).flat());

    const filteringProps = useSelector<StoreState, any>(
      (state) => state.app.filteringGeoJsonLayers
    );

    // console.log("geoJsonLayers in map before ", geoJsonLayers);
    geoJsonLayers = filterProps(geoJsonLayers, filteringProps);
    // console.log("geoJsonLayers in map after ", geoJsonLayers);

    let restNodes: any = [];
    let aiaNodes: any = [];
    let worstState: number = -1;
    let fillColor = "[0,0,0]";
    if (zoom[0] < 12.5 || zoom[0] === 0) {
      restNodes = geoJsonLayers.filter(
        (i) =>
          i?.properties?.Operator !== "AIA" &&
          i?.properties?.title !== "MS Athens International Airport"
      );
      aiaNodes = geoJsonLayers.filter(
        (i: Feature<Geometry, GeoJsonProperties>) =>
          i?.properties?.Operator === "AIA" ||
          i?.properties?.title === "MS Athens International Airport"
      );

      // find worst state and color
      const states: number[] = aiaNodes.map((i: any) => i?.properties?.State);
      worstState = Math.max(...states);
      const worstStateNode =aiaNodes[states.indexOf(worstState)]; 
      fillColor = worstStateNode?.properties!.fillColor || "[0,0,0]";
      
    } else {
      restNodes = geoJsonLayers;
    }
    const worstStateMarkerStateColor = `rgb(${fillColor.toString()})`;

    // if (zoom[0] < 10 && zoom[0] !== 0) {
    //   aiaNodes = []
    // }
    // init zoom when first time
    if (zoom[0] == 0) {
      zoom[0] = 10.77;
    }
    const clusterSize = Math.exp(zoom[0] / 2.5);
    const fontSize = Math.exp(zoom[0] / 4.8);
    // console.log(aiaNodes,restNodes, zoom)
    return (
      <>
        {restNodes.map((node: any, index: any) => (
          <MapNodeSelector
            key={`node-${node.properties!.id}`}
            {...node}
            mouseClick={mouseClick}
            mouseMove={mouseMove}
            classes={[]}
            zoom={zoom[0]}
          />
        ))}
        {aiaNodes?.length > 0 && (
          <Marker
            key={`cluster-aia`}
            latitude={37.9346}
            longitude={23.946645742132063}
          >
            <div
              className='cluster-marker'
              style={{
                width: `${clusterSize}px`,
                height: `${clusterSize}px`,
                fontSize: `${fontSize}px`,
                lineHeight: "normal",
                background: worstStateMarkerStateColor,
              }}
            >
              {"Athens International Airport"}
            </div>
          </Marker>
        )}
      </>
    );
  }
);
export const MapNodeSelector = React.memo(
  ({
    geometry,
    properties,
    type,
    mouseMove,
    mouseClick,
    classes,
    zoom,
  }: CommonMouseEvents & Feature & { classes: string[]; zoom?: number }) => {
    let component = <></>;
    switch (geometry.type) {
      case "Point":
        component = (
          <CustomMarker
            {...{
              geometry,
              type,
              properties,
              mouseClick,
              mouseMove,
              classes,
              zoom,
            }}
          />
        );
        break;
      case "Polygon":
        component = (
          <MPolygon
            {...{ geometry, type, properties, mouseClick, mouseMove }}
          />
        );
        break;
      case "MultiLineString":
        component = (
          <Line {...{ geometry, type, properties, mouseClick, mouseMove }} />
        );
        break;
      default:
        component = <></>;
        break;
    }
    return component;
  }
);

export const PopupTest: React.FC<{ xy: PopupState | null; children: any }> = ({
  xy,
  children,
}) => {
  const [x, y] = xy || INITIAL_POPUP_XY;

  return (
    <div style={{ top: y + 10, left: x + 10 }} className='mapboxgl-popup'>
      <div className='mapboxgl-popup-content'>{children}</div>
    </div>
  );
};

const MPolygon = React.memo(
  ({ mouseClick, mouseMove, properties, geometry }: NodeProps<Polygon>) => {
    const { current: map } = useMap();
    const initalOpacity = 0.6;
    const randIdSource = `source-${properties!.id}`;
    const randIdPolygon = `polygon-${properties!.id}`;
    let fillColor = properties!.fillColor || "[0,0,0]";
    fillColor = `rgb(${fillColor.toString()})`;
    const layerStyle: LayerProps = {
      id: randIdPolygon,
      type: "fill",
      paint: {
        "fill-color": fillColor, // blue color fill
        "fill-opacity": initalOpacity,
      },
    };
    map!.on("click", randIdPolygon, (e) => {
      console.log("Polygon Click", e);
      mouseClick(e, properties!);
    });
    map!.on("mousemove", randIdPolygon, (e) => {
      mouseMove(e.originalEvent, properties!);
    });

    return (
      <Source id={randIdSource} type='geojson' data={geometry}>
        <Layer {...layerStyle} />
      </Source>
    );
  }
);

export const CustomMarker: React.FC<
  NodeProps<Point> & { classes: string[]; zoom?: number }
> = React.memo(
  ({ mouseClick, mouseMove, geometry, properties, classes, zoom }) => {
    const icon = properties!.icon;
    const [lng, lat] = geometry.coordinates;

    let width = 19.2;
    if (zoom) {
      width = Math.exp(zoom / (5 - zoom / 8));
      if (zoom >= 12.5) {
        width = width * 0.8;
      }

      if (zoom < 9) {
        width = 10.5;
      }
    }
    let height = width / 3;

    let fillColor = properties!.fillColor || "[0,0,0]";
    const MarkerStateColor = `rgb(${fillColor.toString()})`;
    return (
      <Marker
        onClick={(e) => mouseClick(e, properties!)}
        longitude={lng}
        latitude={lat}
        anchor='bottom'
      >
        <div
          onMouseMove={(e) => mouseMove(e as any, properties!)}
          className='c-marker'
        >
          <div className={`marker-container scale-hov ${classes.join(" ")}`}>
            <div
              className='marker-indicator'
              style={{
                backgroundColor: MarkerStateColor,
                width: `${width}px`,
                height: `${height}px`,
              }}
            />
            <div
              className='img-marker-container'
              style={{ width: `${width}px` }}
            >
              <img
                className='m-img'
                src={GetImageFromString(icon)}
                alt={"Node"}
              />
            </div>
          </div>
        </div>
      </Marker>
    );
  }
);

const Line = React.memo(
  ({
    mouseClick,
    mouseMove,
    properties,
    geometry,
  }: NodeProps<MultiLineString>) => {
    const { current: map } = useMap();
    // const initalOpacity = 0.6;
    const randIdSource = `source-${properties!.id}`;
    const randIdLine = `line-${properties!.id}`;

    let fillColor = properties!.fillColor || "[0,0,0]";
    fillColor = `rgb(${fillColor.toString()})`;
    const layerStyle: LayerProps = {
      id: randIdLine,
      type: "line",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": fillColor, // blue color fill
        "line-width": 2,
        "line-opacity": 1,
        // 'fill-opacity': initalOpacity,
      },
    };
    map!.on("click", randIdLine, (e) => {
      console.log("line Click", e);
      mouseClick(e, properties!);
    });
    map!.on("mousemove", randIdLine, (e) => {
      mouseMove(e.originalEvent, properties!);
    });

    return (
      <Source id={randIdSource} type='geojson' data={geometry}>
        <Layer {...layerStyle} />
      </Source>
    );
  }
);
