import axios from 'axios'
import { GET_RESILIENCE } from '../constants'
import { getAccessToken } from "../constants";

export function getResilienceApi(): Promise<any> {  
  const token = getAccessToken()
  const headers = { "Authorization": `Bearer ${token}` }
  
  return new Promise((resolve, reject) => {
    axios
      .get(GET_RESILIENCE, { headers })
      .then(async function (response) {
        resolve(response!.data);
      })
      .catch(function (error) {
        reject(error);
      })
      .finally(function () { });
  });
}
