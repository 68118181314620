import React, { useEffect, useRef, useState } from "react";
import { StoreState } from "../../redux/state";
import { Modal, Icon, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  resetModal,
  selectNode,
  toggleNewSimulationModal,
} from '../../redux/simulationsSlice'
import { SimulationForm } from "../Simulations-form/Simulation-form";
import { IncidentsForm } from "../Incidents/IncidentForm";
import { selectIncidentNode, toggleIncidentModal } from "../../redux/incidentsSlice";
import { setAppState } from "../../redux/appSlice";

type FormProps = { };
export const MergedAppModals: React.FC<FormProps> = ({
}) => {
  const dispatch = useDispatch();

  const incidentFormRef = useRef();
  
  const $simModalIsOpen = useSelector<StoreState, boolean>(
    (state) => state.simulations.newSimulationModalIsOpen
  );

  const $incidentsFormIsOpen = useSelector<StoreState, boolean>(
    (state) => state.incidents.incidentModalIsOpen
  );


  const handleIncidenFormCancel = () => {
    console.log(
      "incidentFormRefincidentFormRefincidentFormRef",
      incidentFormRef
    );
    (incidentFormRef as any).current.resetFields();
    dispatch(setAppState("incidents"))
    dispatch(selectIncidentNode(null));
    dispatch(toggleIncidentModal(false));
  };
  const handleSimFormCancel = () => {
    dispatch(toggleNewSimulationModal(false));
    dispatch(selectNode({ nodeId: null }));
    dispatch(resetModal({ resetModal: true }));
  };



  return (
    <>
      {$simModalIsOpen && (
        //@ts-ignore
        <Modal
          title='Cascading Effect Simulator'
          visible={$simModalIsOpen}
          width={800}
          footer={null}
          style={{ zIndex: 10 }}
          // onOk={handleOk}
          onCancel={handleSimFormCancel}
        >
          <SimulationForm />
        </Modal>
      )}
      {$incidentsFormIsOpen && (
        //@ts-ignore
        <Modal
          title='Create Incident'
          visible={$incidentsFormIsOpen}
          width={800}
          footer={null}
          style={{ zIndex: 10 }}
          set
          // onOk={handleOk}
          onCancel={handleIncidenFormCancel}
        >
          <IncidentsForm
            //@ts-ignore
            ref={incidentFormRef}
          />
        </Modal>
      )}
    </>
  );
};
