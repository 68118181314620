export const StyleVariables = {
  primaryColor: "#bfbfbf",
  primaryHoverColor: "white",
  primaryBg: "#242730",
  primaryBgRGBA: "rgb(36 39 48 / 50%)",
  secondaryBg: "#29323c",
  activeBorder: "#5d5a5a",
  borderColor: "#3b4652",
  font: {
    size: { sm: "12px" },
  },
  scroll: {
    trackColor: "#242730",
    thumbColor: "#424242",
  },
};
