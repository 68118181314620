import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRealTimeApi } from "../api/real-time-interval";
import { getResilienceApi } from '../api/resilience.api'
import { lines } from "./lines";

export type RealTimeLatest = { config: any, datasets: any }
export interface RealTimeState {
  loading: boolean;
  data: { [key: string]: any };
  lastSync: string;
  latestData: RealTimeLatest
  resilience: number;
}

const initialState: RealTimeState = {
  loading: false,
  data: {},
  lastSync: "",
  latestData: { config: {}, datasets: [] },
  resilience: 0
};

export const getResilience = createAsyncThunk(
  "realTimeData/getResilience",
  async () => {
    const data = await getResilienceApi()
    return data?.resilience;
  }
);

export const getRealTimeAction = createAsyncThunk(
  "realTimeData/getRealTime",
  async () => {
    const data = await getRealTimeApi()
    return data;
  }
);

export const realTimeDataSlice = createSlice({
  name: "realTimeData",
  initialState,
  reducers: {
    setRealTimeData: (state, { payload }) => {
      return { ...state, loading: false, latestData: payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRealTimeAction.pending, (state, { payload }) => {
        return { ...state, loading: true };
      })
      .addCase(getRealTimeAction.fulfilled, (state, { payload }) => {
        const date = new Date();

        return {
          ...state,
          data: payload,
          loading: false,
          lastSync: date.toLocaleString(),
        };
      })
      .addCase(getResilience.fulfilled, (state, { payload }) => {

        return {
          ...state,
          resilience: payload,
        };
      })
  }

});



// Action creators are generated for each case reducer function
export const { setRealTimeData } =
  realTimeDataSlice.actions;

export default realTimeDataSlice.reducer;

