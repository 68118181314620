export const INCIDENT_PRIORITIES = [
    {
        name: "Priority not assessed",
        value: "Priority not assessed",
    },
    {
        name: "No estimated priority for mission accomplishment.",
        value: "No estimated priority for missi",
    },
    {
        name: "Minor priority to mission completion due to no aggravation.",
        value: "Minor priority to mission compl",
    },
    {
        name: "Standard priority due to possible limited aggravation in absence of mission accomplishment.",
        value: "Standard priority due to possib",
    },
    {
        name: "Critical priority due to possible fatal worsening without mission accomplishment",
        value: "Critical priority due to possib",
    },
    {
        name: "Extremely critical priority due to possible fatal mass worsening without mission accomplishment",
        value: "Extremely critical priority due",
    },
];

export const INCIDENT_SEVERITIES =
    ["Extreme", "Severe", "Moderate", "Minor", "Unknown"];

export const INCIDENT_SCALE_OF_IMPACTS =
    ["Unknown", "No damage", "Minor", "Moderate", "Large", "Massive"]

export const INCIDENT_REPORT_RECIPIENTS =
    ["NCIP:_National_Contact_Point_fo", "Police_authorities", "Fire_service", "Medical_emergency_services"]