import { Slider, Row, Col } from "antd";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import styled from "styled-components";
import { Button } from "../ui/components";
import store from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { getMitigationActions, getMitigationActionsStatus, setResilience, setTimelineStep } from "../../redux/simulationsSlice";
import { StoreState } from "../../redux/state";
import { Execution, Simulation } from "../../api/simulations.api";
import { realTimeInterval } from "../../api/real-time-interval";

const SliderContainer = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
`;

const _marks: Marks = {
  0: "Sim 1",
  25: "Sim 2",
  50: "Sim 3",
  75: "Sim 4",
  100: "Sim5",
};
export type TimelineLabel = { label: string; value: string };
type Marks = { [key: string]: string };
type Props = {
  emitValueOnStart: boolean;
  emitValue: (val: TimelineLabel) => void;
  playingDelayInMs: number;
};

export const Timeline: React.FC<Props> = forwardRef(
  ({ emitValueOnStart, playingDelayInMs }, ref) => {
    useImperativeHandle(ref, () => ({
      stopTimeline() {
        setGoNext(0);
        setPlaying(false);
        clearInterval(intervalId);
        setIntervalId(null);
      },
    }));
    const dispatch = useDispatch();

    const [suggestActionsclicked, setSuggestActionsclicked] = useState<boolean>(false);
    const [polling, setPolling] = useState<NodeJS.Timeout | null>(null);

    const $timelineLabels = useSelector<StoreState, StoreState['simulations']['timelineLabels']>(
      (state) => state.simulations.timelineLabels
    );

    const $mitigationActionsStatus = useSelector<StoreState, any>((state) => state.simulations.mitigationActionsStatus);

    const $executionResilience = useSelector<StoreState, string | null>(
      (state) => state.simulations.activeResilience
    );

    const $activeSim = useSelector<StoreState, Simulation>(
      (state) => state.simulations.activeSimulation as Simulation
    );

    const $activeExecution = useSelector<StoreState, Execution | null>(
      (state) => state.simulations.activeExecution
    );

    const [marks, setMarks] = useState<any>({});
    const [playing, setPlaying] = useState(false);
    const [value, setValue] = useState(1);
    const [percentages, setPercentages] = useState<string[]>([]);
    const [mitActionStatusValue, setmitActionStatusValue] = useState("");
    const [mitActionStatusColor, setmitActionStatusColor] = useState("");
    const [simulationResilience, setsimulationResilience] = useState<number>(0);

    const [goNext, setGoNext] = useState(0);
    const [intervalId, setIntervalId] = useState<any>();

    const preapareTimeLineLabels = (
      labels: TimelineLabel[]
    ): { [key: number]: string } => {
      let obj: Marks = {};
      const marksPercentage = getTimelineLimits(labels.length);
      setPercentages(marksPercentage);
      marksPercentage.map((val, index) => {
        obj[val] = labels[index].label;
        return null;
      });
      return obj;
    };


    useEffect(() => {
      console.log("asdasdas " ,$activeExecution)
      if ($activeExecution?.mitigation_actions_id) {
        dispatch(
          getMitigationActionsStatus($activeExecution.mitigation_actions_id)
        );
      }

    }, [$activeExecution]);

    useEffect(() => {

      console.log("$mitigationActionsStatus  ", $mitigationActionsStatus, suggestActionsclicked);
      if (
        $mitigationActionsStatus?.execution_id !== $activeExecution?._id ||
        suggestActionsclicked == false
      ) {
        console.log("returning mit actions")
        return;
      }
      if ($mitigationActionsStatus.terminated == true && polling !== undefined) {
        clearInterval(polling as NodeJS.Timeout);
        setPolling(null);
      }

      if ($mitigationActionsStatus.terminated == false && polling) {
        setmitActionStatusValue("Solution is calculating...");
        setmitActionStatusColor("yellow");
      }
      else if ($mitigationActionsStatus.terminated == true && $mitigationActionsStatus.solution == false) {
        setmitActionStatusValue("Solution not found");
        setmitActionStatusColor("red")
      } else if ($mitigationActionsStatus.terminated == true && $mitigationActionsStatus.solution == true) {
        setmitActionStatusValue("Solution found");
        setmitActionStatusColor("green");

        dispatch(getMitigationActions({ budget: 10000, _id: $activeSim._id }));
      }

    }, [$mitigationActionsStatus]);

    useEffect(() => {
      if (marks[100] == "RN" || !$executionResilience) {
        return
      }
      const res: any = Number($executionResilience).toPrecision(4);
      setsimulationResilience(res);
    }, [$executionResilience]);

    useEffect(() => {
      if ($activeSim) {
        if ($timelineLabels && $timelineLabels.length > 0) {
          store.dispatch(setTimelineStep($timelineLabels[0].value));
        }
      }
    }, [$activeSim]);

    useEffect(() => {
      setMarks(_marks);
      const marks = preapareTimeLineLabels($timelineLabels);
      setMarks(marks);
    }, [$timelineLabels]);

    useEffect(() => {
      const vals = Object.keys(marks);
      if (goNext === vals.length && goNext != 0) {
        setGoNext(vals.length - 1);
        setPlaying(false);
        clearInterval(intervalId);
        setIntervalId(null);
      } else {
        valueChanged($timelineLabels[goNext]);
        setValue(Number(vals[goNext]));
      }
      dispatch(setResilience(simulationResilience));
      if (goNext === vals.length - 1) {
        if (marks[100] == "RN") {
          dispatch(setResilience($activeSim?.resilience))
        }
      }
    }, [goNext, marks]); // eslint-disable-line

    const playPause = async () => {
      if (playing) {
        setPlaying(false);
        clearInterval(intervalId);
        setIntervalId(null);
        return;
      }

      setPlaying(true);
      startPlaying();
      const newIntervalId = setInterval(startPlaying, playingDelayInMs);
      function startPlaying() {
        setGoNext((prevIndex) => prevIndex + 1);
      }
      setIntervalId(newIntervalId);
    };

    const moveBackForward = async (mode: string) => {
      let index = goNext
      if (mode === "forward") {
        index = index + 1
      } else {
        index = index - 1
      }

      const vals = Object.keys(marks);
      if (index === vals.length) {
        setGoNext(0);
      } else {
        setGoNext(index);
      }
      if (index < 0) {
        setGoNext(vals.length - 1)
      }
    }

    const onChange = (percentageSelected: any) => {
      setValue(percentageSelected);

      const pIndex = percentages.findIndex((val) => val == percentageSelected); // eslint-disable-line
      if (pIndex === -1) return;
      valueChanged($timelineLabels[pIndex]);
      setGoNext(pIndex);
    };

    const valueChanged = (val: TimelineLabel) => {
      if (!val) return

      store.dispatch(setTimelineStep(val?.value));
    };

    const reload = (val: any) => {
      setGoNext(0)
    };

    const suggestActions = () => {

      if (suggestActionsclicked === true) {
        return;
      }
      setSuggestActionsclicked(true)
      setPolling(realTimeInterval(1000, updateMitigationActionStatus));
    }
    const updateMitigationActionStatus = () => {
      console.log("aaaa " ,$activeExecution);
      if ($activeExecution?.mitigation_actions_id) {
        dispatch(
          getMitigationActionsStatus($activeExecution.mitigation_actions_id)
        );
      }
    }

    return (
      <SliderContainer>
        <Row style={{}}>
          <div>
            {suggestActionsclicked == false && (
              <>
                <Button
                  style={{ position: "absolute", left: "0" }}
                  rtype='secondary'
                  width='139px'
                  onClick={suggestActions}
                >
                  Suggest Actions
                </Button>
              </>
            )}
            <p
              style={{ textAlign: "center", fontSize: "18px", fontWeight: 300 }}
            >
              <strong>Simulation</strong> - Viewing cascading effects
            </p>
            <div
              style={{
                textAlign: "center",
                fontSize: "12px",
                fontWeight: 300,
                color: mitActionStatusColor,
              }}
            >
              {mitActionStatusValue}
            </div>
          </div>
        </Row>
        <Row>
          <Col span={1} style={{ transform: "translateY(5px)" }}>
            <Button
              rtype='primary'
              width='30px'
              icon='reload'
              onClick={reload}
            ></Button>
          </Col>
          <Col span={1} style={{ transform: "translateY(5px)" }}>
            <Button
              rtype='primary'
              width='30px'
              icon={playing ? "pause" : "play-circle"}
              onClick={playPause}
            ></Button>
          </Col>
          <Col span={1} style={{ transform: "translateY(5px)" }}>
            <Button
              rtype='primary'
              width='30px'
              icon={"caret-left"}
              onClick={() => moveBackForward("back")}
            ></Button>
          </Col>
          <Col span={1} style={{ transform: "translateY(5px)" }}>
            <Button
              rtype='primary'
              width='30px'
              icon={"caret-right"}
              onClick={() => moveBackForward("forward")}
            ></Button>
          </Col>
          <Col span={22} style={{ paddingLeft: "20px", width: "80%" }}>
            <Slider
              marks={marks}
              step={null}
              defaultValue={37}
              onChange={onChange}
              value={value}
            />
          </Col>
        </Row>
      </SliderContainer>
    );
  }
);

function getTimelineLimits(length: number) {
  const percentageArray = Array.from({ length }, (_, i) =>
    ((i / (length - 1)) * 100).toFixed(0)
  );
  return percentageArray;
}
