import styled from "styled-components";
import { StyleVariables } from "../../styles/variables";
import * as Ant from "antd";

import { Checkbox } from 'antd'


export const Select = styled(Ant.Select)`
  &&& {
    width: 100%;
    .ant-select-selection {
      color: white;
      width: 100%;
      font-size: ${StyleVariables.font.size.sm};
      background-color: ${StyleVariables.secondaryBg};
      border-color: ${StyleVariables.borderColor};
      transition: all 0.3s ease-in-out;
      --Select-focusedHighlight: none;
      &:hover {
        color: white;
        border-color: ${StyleVariables.borderColor};
        background-color: ${StyleVariables.primaryBg};
      }

      .ant-select-arrow {
        color: #6a7485;
      }
    }
  }
`;
export const Option = styled(Ant.Select.Option)`
  &&& {
    color: white;
    background-color: ${StyleVariables.secondaryBg};
  }
`;
type buttonRtypes = "primary" | "secondary" | "default" | "bg1" | "bg2";
type BtnProps = { rtype?: buttonRtypes; width?: string; height?: string };
type Btn = Ant.Button["props"] & BtnProps;

function BtnTypeToColor(type?: buttonRtypes): string {
  switch (type) {
    case "primary":
      return "#2c9668";
    case "secondary":
      return "#6a7485";
    case "bg1":
      return StyleVariables.primaryBg;
    case "bg2":
      return StyleVariables.secondaryBg;
    default:
      return "#6a7485";
  }
}

export const Button: React.FunctionComponent<Btn> = styled(
  Ant.Button
) <BtnProps>`
  &&& {
    background-color: ${(props) => BtnTypeToColor(props.rtype)};
    font-size: ${StyleVariables.font.size.sm};
    color: white;
    width: ${(props) => props.width || "100%"};
    height: ${(props) => props?.height};
    border-color: ${StyleVariables.borderColor};
    border-radius: 2px;
    &:hover {
      transition: background-color 0.3s ease-in-out;
      background-color: #576171;
    }
  }
`;
export const InputNumber = styled(Ant.InputNumber)`
  &&& {
    color: ${StyleVariables.primaryColor};
    font-size: ${StyleVariables.font.size.sm};
    background-color: ${StyleVariables.secondaryBg};
    border-color: ${StyleVariables.borderColor};
    --Input-focusedHighlight: none;
    &:hover {
      border-color: ${StyleVariables.borderColor};
      background-color: ${StyleVariables.primaryBg};
    }
  }
`;

export const InputText = styled(Ant.Input)`
  &&& {
    color: ${StyleVariables.primaryColor};
    font-size: ${StyleVariables.font.size.sm};
    background-color: ${StyleVariables.secondaryBg};
    border-color: ${StyleVariables.borderColor};
    --Input-focusedHighlight: none;
    &:hover {
      border-color: ${StyleVariables.borderColor};
      background-color: ${StyleVariables.primaryBg};
    }
  }
`;


export const TextArea = styled(Ant.Input.TextArea)`
  &&& {
    color: ${StyleVariables.primaryColor};
    font-size: ${StyleVariables.font.size.sm};
    background-color: ${StyleVariables.secondaryBg};
    border-color: ${StyleVariables.borderColor};
    --Input-focusedHighlight: none;
    &:hover {
      border-color: ${StyleVariables.borderColor};
      background-color: ${StyleVariables.primaryBg};
    }
  }
`;