import { type Trigger } from '../../models/trigger'

export const _TRIGGERS: Trigger[] = [
  {
    label: 'Airport Transportation (Flights) Service',
    id: 1,
    triggers: [
      'arrival_closed',
      'arrival_compromised',
      'arrival_shutdown',
      'boarding_closed',
      'boarding_compromised',
      'boarding_shutdown',
      'BS_alerted',
      'BS_interrupted',
      'BS_shutdown',
      'CC_alerted',
      'CC_shutdown',
      'CS_alerted',
      'CS_interrupted',
      'CS_shutdown',
      'evacuation_full',
      'evacuation_partial',
      'info_sys_compromised',
      'info_sys_down',
      'info_sys_tampered',
      'luggage_area_closed',
      'luggage_area_compromised',
      'luggage_area_shutdown',
      'PIS_alerted',
      'PIS_interrupted',
      'PIS_shutdown'
    ]
  },
  {
    label: 'Airport Evacuation',
    id: 2,
    triggers: [
      'evacuation_full',
      'evacuation_partial',
      'flight_service_alerted',
      'flight_service_limited',
      'flight_service_shutdown',
      'MS_alerted',
      'MS_interrupted',
      'MS_shutdown',
      'phys_security_compromised',
      'phys_security_limited'
    ]
  },
  {
    label: 'Boarding Service',
    id: 3,
    triggers: [
      'boarding_closed',
      'boarding_compromised',
      'boarding_shutdown',
      'info_sys_compromised',
      'info_sys_down',
      'info_sys_tampered',
      'luggage_area_closed',
      'luggage_area_compromised',
      'luggage_area_shutdown'
    ]
  },
  {
    label: 'Check In Service',
    id: 4,
    triggers: [
      'info_sys_compromised',
      'info_sys_down',
      'info_sys_tampered',
      'luggage_area_closed',
      'luggage_area_compromised',
      'luggage_area_shutdown'
    ]
  },
  {
    label: 'Call Center Service',
    id: 5,
    triggers: [
      'luggage_area_closed',
      'luggage_area_compromised',
      'luggage_area_shutdown'
    ]
  },
  {
    label: 'Passenger Inspection Service',
    id: 7,
    triggers: [
      'info_sys_compromised',
      'info_sys_down',
      'info_sys_tampered',
      'luggage_area_closed',
      'luggage_area_shutdown'
    ]
  },
  {
    label: 'Flight Info Display System',
    id: 8,
    triggers: ['fake_info']
  },
  {
    label: 'Hospitals',
    id: 9,
    triggers: ['evacuation_full', 'evacuation_partial']
  },
  {
    label: 'Power Supply',
    id: 10,
    triggers: ['problem']
  },
  {
    label: 'Metro Evacuation Service',
    id: 11,
    triggers: ['evacuation_full', 'evacuation_partial', 'MS_shutdown']
  },
  {
    label: 'Electrified Rail Tracks (physical)',
    id: 13,
    triggers: [
      'MO_alerted',
      'MO_interrupted',
      'MO_shutdown',
      'power_interrupted',
      'power_limited'
    ]
  },
  {
    label: 'Luggage Area (Physical)',
    id: 19,
    triggers: [
      'BMS_corrupted',
      'BMS_down',
      'BMS_tampered',
      'explosion',
      'info_sys_compromised',
      'info_sys_down',
      'info_sys_tampered'
    ]
  },
  {
    label: 'ATTD Road Operations Center',
    id: 21,
    triggers: ['evacuation_full']
  },
  {
    label: 'Metro Operations Center',
    id: 22,
    triggers: ['evacuation_full', 'evacuation_partial']
  },
  {
    label: 'Attack 2nd step (Info)',
    id: 23,
    triggers: ['workstation_corrupted', 'workstation_down']
  },
  {
    label: 'admin. workstation',
    id: 24,
    triggers: ['attack_USB']
  },
  {
    label: 'Attack 1st step (USB)',
    id: 25,
    triggers: ['attack_USB']
  },
  {
    label: 'Building Mgmt System (incl. AC)',
    id: 26,
    triggers: ['workstation_corrupted']
  },
  {
    label: 'Passenger Info System',
    id: 27,
    triggers: ['fake_info']
  },
  {
    label: 'Physical Security Service Airport',
    id: 29,
    triggers: ['problem']
  },
  {
    label: 'Metro Stations (Line 3)',
    id: 31,
    triggers: [
      'MS_alerted',
      'MS_interrupted',
      'MS_shutdown',
      'Tracks_compromised',
      'Tracks_down',
      'TSS_compromised',
      'TSS_down'
    ]
  },
  {
    label: 'ATTD East Road (in)',
    id: 32,
    triggers: [
      'ATTD_alerted',
      'ATTD_shutdown',
      'road_blocked',
      'road_congested',
      'road_interrupted'
    ]
  },
  {
    label: 'ATTD West Road (out)',
    id: 33,
    triggers: [
      'ATTD_alerted',
      'ATTD_shutdown',
      'road_blocked',
      'road_congested',
      'road_interrupted'
    ]
  },
  {
    label: 'airport premises',
    id: 34,
    triggers: [
      'evacuation_full',
      'evacuation_partial',
      'info_sys_compromised',
      'info_sys_down',
      'info_sys_tampered',
      'MS_shutdown'
    ]
  },
  {
    label: 'Boarding Control System',
    id: 35,
    triggers: ['fake_info']
  },
  {
    label: 'Train Signaling System',
    id: 37,
    triggers: ['power_interrupted', 'power_limited']
  },
  {
    label: 'Inbound Road to Airport',
    id: 38,
    triggers: [
      'airport_alerted',
      'airport_interrupted',
      'airport_shutdown',
      'ATTD_alerted',
      'ATTD_shutdown',
      'road_blocked',
      'road_congested',
      'road_interrupted'
    ]
  },
  {
    label: 'Outbound Road from Airport',
    id: 39,
    triggers: [
      'airport_alerted',
      'airport_interrupted',
      'airport_shutdown',
      'ATTD_alerted',
      'ATTD_shutdown'
    ]
  },
  {
    label: 'Attack 3rd Step (Bomb)',
    id: 40,
    triggers: ['Bomb']
  },
  {
    label: 'MS Athens International Airport',
    id: 41,
    triggers: [
      'airport_alerted',
      'airport_interrupted',
      'airport_shutdown',
      'evacuation_full',
      'evacuation_partial',
      'explosion',
      'MS_alerted',
      'MS_interrupted',
      'MS_shutdown',
      'Tracks_compromised',
      'Tracks_down',
      'TSS_compromised',
      'TSS_down'
    ]
  },
  {
    label: 'MS Peania-Kantza',
    id: 42,
    triggers: [
      'MS_alerted',
      'MS_interrupted',
      'MS_shutdown',
      'Tracks_compromised',
      'Tracks_down',
      'TSS_compromised',
      'TSS_down'
    ]
  },
  {
    label: 'MS Pallini',
    id: 43,
    triggers: [
      'MS_alerted',
      'MS_interrupted',
      'MS_shutdown',
      'Tracks_compromised',
      'Tracks_down',
      'TSS_compromised',
      'TSS_down'
    ]
  },
  {
    label: 'MS Koropi',
    id: 44,
    triggers: [
      'MS_alerted',
      'MS_interrupted',
      'MS_shutdown',
      'Tracks_compromised',
      'Tracks_down',
      'TSS_compromised',
      'TSS_down'
    ]
  },
  {
    label: 'Attack 4th Step (Bomb)',
    id: 45,
    triggers: ['Bomb']
  },
  {
    label: 'Arrival Hall',
    id: 46,
    triggers: ['BMS_corrupted', 'BMS_down', 'BMS_tampered']
  },
  {
    label: 'Boarding Hall',
    id: 47,
    triggers: [
      'BMS_corrupted',
      'BMS_down',
      'BMS_tampered',
      'info_sys_compromised',
      'info_sys_down',
      'info_sys_tampered'
    ]
  }
].sort((a, b) => a.label.localeCompare(b.label))

export const _ALL_NODES: any[] = [
  {
    label: 'Airport Transportation (Flights) Service',
    id: 1,
    triggers: [
      'arrival_closed',
      'arrival_compromised',
      'arrival_shutdown',
      'boarding_closed',
      'boarding_compromised',
      'boarding_shutdown',
      'BS_alerted',
      'BS_interrupted',
      'BS_shutdown',
      'CC_alerted',
      'CC_shutdown',
      'CS_alerted',
      'CS_interrupted',
      'CS_shutdown',
      'evacuation_full',
      'evacuation_partial',
      'info_sys_compromised',
      'info_sys_down',
      'info_sys_tampered',
      'luggage_area_closed',
      'luggage_area_compromised',
      'luggage_area_shutdown',
      'PIS_alerted',
      'PIS_interrupted',
      'PIS_shutdown'
    ]
  },
  {
    label: 'Airport Evacuation',
    id: 2,
    triggers: [
      'evacuation_full',
      'evacuation_partial',
      'flight_service_alerted',
      'flight_service_limited',
      'flight_service_shutdown',
      'MS_alerted',
      'MS_interrupted',
      'MS_shutdown',
      'phys_security_compromised',
      'phys_security_limited'
    ]
  },
  {
    label: 'Boarding Service',
    id: 3,
    triggers: [
      'boarding_closed',
      'boarding_compromised',
      'boarding_shutdown',
      'info_sys_compromised',
      'info_sys_down',
      'info_sys_tampered',
      'luggage_area_closed',
      'luggage_area_compromised',
      'luggage_area_shutdown'
    ]
  },
  {
    label: 'Check In Service',
    id: 4,
    triggers: [
      'info_sys_compromised',
      'info_sys_down',
      'info_sys_tampered',
      'luggage_area_closed',
      'luggage_area_compromised',
      'luggage_area_shutdown'
    ]
  },
  {
    label: 'Call Center Service',
    id: 5,
    triggers: [
      'luggage_area_closed',
      'luggage_area_compromised',
      'luggage_area_shutdown'
    ]
  },
  {
    label: 'Passenger Inspection Service',
    id: 7,
    triggers: [
      'info_sys_compromised',
      'info_sys_down',
      'info_sys_tampered',
      'luggage_area_closed',
      'luggage_area_shutdown'
    ]
  },
  {
    label: 'Flight Info Display System',
    id: 8,
    triggers: ['fake_info']
  },
  {
    label: 'Hospitals',
    id: 9,
    triggers: ['evacuation_full', 'evacuation_partial']
  },
  {
    label: 'Power Supply',
    id: 10,
    triggers: ['problem']
  },
  {
    label: 'Metro Evacuation Service',
    id: 11,
    triggers: ['evacuation_full', 'evacuation_partial', 'MS_shutdown']
  },
  {
    label: 'Electrified Rail Tracks (physical)',
    id: 13,
    triggers: [
      'MO_alerted',
      'MO_interrupted',
      'MO_shutdown',
      'power_interrupted',
      'power_limited'
    ]
  },
  {
    label: 'Luggage Area (Physical)',
    id: 19,
    triggers: [
      'BMS_corrupted',
      'BMS_down',
      'BMS_tampered',
      'explosion',
      'info_sys_compromised',
      'info_sys_down',
      'info_sys_tampered'
    ]
  },
  {
    label: 'ATTD Road Operations Center',
    id: 21,
    triggers: ['evacuation_full']
  },
  {
    label: 'Metro Operations Center',
    id: 22,
    triggers: ['evacuation_full', 'evacuation_partial']
  },
  {
    label: 'Attack 2nd step (Info)',
    id: 23,
    triggers: ['workstation_corrupted', 'workstation_down']
  },
  {
    label: 'admin. workstation',
    id: 24,
    triggers: ['attack_USB']
  },
  {
    label: 'Attack 1st step (USB)',
    id: 25,
    triggers: ['attack_USB']
  },
  {
    label: 'Building Mgmt System (incl. AC)',
    id: 26,
    triggers: ['workstation_corrupted']
  },
  {
    label: 'Passenger Info System',
    id: 27,
    triggers: ['fake_info']
  },
  {
    label: 'Physical Security Service Airport',
    id: 29,
    triggers: ['problem']
  },
  {
    label: 'Metro Stations (Line 3)',
    id: 31,
    triggers: [
      'MS_alerted',
      'MS_interrupted',
      'MS_shutdown',
      'Tracks_compromised',
      'Tracks_down',
      'TSS_compromised',
      'TSS_down'
    ]
  },
  {
    label: 'ATTD East Road (in)',
    id: 32,
    triggers: [
      'ATTD_alerted',
      'ATTD_shutdown',
      'road_blocked',
      'road_congested',
      'road_interrupted'
    ]
  },
  {
    label: 'ATTD West Road (out)',
    id: 33,
    triggers: [
      'ATTD_alerted',
      'ATTD_shutdown',
      'road_blocked',
      'road_congested',
      'road_interrupted'
    ]
  },
  {
    label: 'airport premises',
    id: 34,
    triggers: [
      'evacuation_full',
      'evacuation_partial',
      'info_sys_compromised',
      'info_sys_down',
      'info_sys_tampered',
      'MS_shutdown'
    ]
  },
  {
    label: 'Boarding Control System',
    id: 35,
    triggers: ['fake_info']
  },
  {
    label: 'Train Signaling System',
    id: 37,
    triggers: ['power_interrupted', 'power_limited']
  },
  {
    label: 'Inbound Road to Airport',
    id: 38,
    triggers: [
      'airport_alerted',
      'airport_interrupted',
      'airport_shutdown',
      'ATTD_alerted',
      'ATTD_shutdown',
      'road_blocked',
      'road_congested',
      'road_interrupted'
    ]
  },
  {
    label: 'Outbound Road from Airport',
    id: 39,
    triggers: [
      'airport_alerted',
      'airport_interrupted',
      'airport_shutdown',
      'ATTD_alerted',
      'ATTD_shutdown'
    ]
  },
  {
    label: 'Attack 3rd Step (Bomb)',
    id: 40,
    triggers: ['Bomb']
  },
  {
    label: 'MS Athens International Airport',
    id: 41,
    triggers: [
      'airport_alerted',
      'airport_interrupted',
      'airport_shutdown',
      'evacuation_full',
      'evacuation_partial',
      'explosion',
      'MS_alerted',
      'MS_interrupted',
      'MS_shutdown',
      'Tracks_compromised',
      'Tracks_down',
      'TSS_compromised',
      'TSS_down'
    ]
  },
  {
    label: 'MS Peania-Kantza',
    id: 42,
    triggers: [
      'MS_alerted',
      'MS_interrupted',
      'MS_shutdown',
      'Tracks_compromised',
      'Tracks_down',
      'TSS_compromised',
      'TSS_down'
    ]
  },
  {
    label: 'MS Pallini',
    id: 43,
    triggers: [
      'MS_alerted',
      'MS_interrupted',
      'MS_shutdown',
      'Tracks_compromised',
      'Tracks_down',
      'TSS_compromised',
      'TSS_down'
    ]
  },
  {
    label: 'MS Koropi',
    id: 44,
    triggers: [
      'MS_alerted',
      'MS_interrupted',
      'MS_shutdown',
      'Tracks_compromised',
      'Tracks_down',
      'TSS_compromised',
      'TSS_down'
    ]
  },
  {
    label: 'Attack 4th Step (Bomb)',
    id: 45,
    triggers: ['Bomb']
  },
  {
    label: 'Arrival Hall',
    id: 46,
    triggers: ['BMS_corrupted', 'BMS_down', 'BMS_tampered']
  },
  {
    label: 'Boarding Hall',
    id: 47,
    triggers: [
      'BMS_corrupted',
      'BMS_down',
      'BMS_tampered',
      'info_sys_compromised',
      'info_sys_down',
      'info_sys_tampered'
    ]
  },
  { id: 100, label:"KOROPI WEST" },
  { id:101, label: "PEANIA WEST" },
  { id:102, label:"PEANIA EAST" },
  { id: 103, label:"KANTZA WEST" },
  { id: 104, label:"KANTZA EAST" },
  { id: 105, label:"MS Syntagma" },
  { id: 106, label:"MS Evangelismos" },
  { id: 107, label:"MS Megaro Moussikis" },
  { id: 108, label:"MS Ambelokipi" },
  { id: 109, label:"MS Panormou" },
  { id: 110, label:"MS Katehaki" },
  { id: 111, label:"MS Ethniki Amyna" },
  { id: 112, label:"MS Holargos" },
  { id: 113, label:"MS Nomismatokopio" },
  { id: 114, label:"MS Aghia Paraskevi" },
  { id: 115, label:"MS Halandri" },
  { id: 116, label:"MS Douk.Plakentias" },
  { id: 117, label:"ATTD West Road 0-5km" },
  { id: 118, label:"ATTD West Road 5-10km" },
  { id: 119, label:"ATTD West Road 10-15km" },
  { id: 120, label:"ATTD West Road 15-20km" },
  { id: 121, label:"ATTD West Road 20-25km" },
  { id: 122, label:"ATTD West Road 25-30km" },
  { id: 123, label:"ATTD West Road 30-35km" },
  { id: 124, label:"ATTD West Road 35-40km" },
  { id: 125, label:"ATTD West Road 40-45km" },
  { id: 126, label:"ATTD East Road 0-5km" },
  { id: 127, label:"ATTD East Road 5-10km" },
  { id: 128, label:"ATTD East Road 10-15km" },
  { id: 129, label:"ATTD East Road 15-20km" },
  { id: 130, label:"ATTD East Road 20-25km" },
  { id: 131, label:"ATTD East Road 25-30km" },
  { id: 132, label:"ATTD East Road 30-35km" },
  { id: 133, label:"ATTD East Road 35-40km" },  
  { id: 134, label: "ATTD East Road 40-45km" },
]
.sort((a, b) => a.label.localeCompare(b.label))