import React from "react";
import { Navigate } from "react-router-dom";
import { useOidc } from "@axa-fr/react-oidc";

type RouteProps = { children:any };

export const ProtectedRoute: React.FC<RouteProps> = ({ children }) => {
  const { isAuthenticated } = useOidc();

  if (!isAuthenticated) {
    return <Navigate to='/login' replace />;
  }

  return children;
};
