// import { Select, Option, Button } from "./components";
import React, { useEffect, useState } from 'react'
import { INewTrigger, Trigger } from "../../models/trigger";
import { Button, Select, Option } from "../ui/components";
import { Row, Col, Form } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import { useSelector } from 'react-redux'
import { _TRIGGERS } from '../../data/triggers/triggers';

const nodes: Trigger[] = _TRIGGERS;

interface Props extends FormComponentProps {
  selectedNodeId: number | null;
  disableCreate?: boolean;
  onNewTrigger?: (newTrigger: INewTrigger) => void;
  onChange?: (data: any) => void;
}
type FormProps = {
  nodeId: number;
  trigger: string;
};
const _TriggerForm: React.FC<Props> = ({
  form,
  selectedNodeId,
  onNewTrigger = () => { },
  disableCreate = false,
  onChange = () => { },
}) => {
  const [triggers, setTriggers] = useState<Array<string>>([]);

  const $resetModal = useSelector((state: any) => state.simulations.resetModal);
  const $nodeId = useSelector((state: any) => state.simulations.nodeId);

  useEffect(() => {
    if ($resetModal === true) {
      form.resetFields();
    }
  }, [$resetModal]);

    useEffect(() => {
      nodeSelect($nodeId)
    }, [$nodeId]);

  function nodeSelect(value: any): void {
    console.log(value)
    const triggers = nodes.find((node) => node.id === value)?.triggers;
    if (triggers) setTriggers(triggers);
  }

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    form.validateFields((err: any, newTrigger: FormProps) => {
      if (!err) {
        console.log({ newTrigger });
        const nodeName = nodes.find((node) => node.id === newTrigger.nodeId);
        console.log({ nodeName });
        if (!nodeName)
          throw new Error("Cant find node name for the given node id!");
        onNewTrigger({ ...newTrigger, nodeName: nodeName.label });
        form.resetFields();
      }
    });
  };

  const { getFieldDecorator } = form;
  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={4}>
        <Col span={disableCreate ? 12 : 9}>
          <Form.Item>
            {getFieldDecorator("nodeId", {
              initialValue: selectedNodeId || "Select a Node",
              rules: [{ required: true, message: "Please select a node!" }],
            })(
              //@ts-ignore
              <Select
                placeholder='Select a Node'
                onChange={nodeSelect as any}
                showSearch={true}
                filterOption={(input: any, option: any) =>
                  (option?.props?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {nodes.map((node) => (
                  <Option key={getRandomHash(5)} value={node.id}>
                    {node.label}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={disableCreate ? 12 : 9}>
          <Form.Item>
            {getFieldDecorator("trigger", {
              rules: [{ required: true, message: "Please select a Trigger!" }],
            })(
              //@ts-ignore
              <Select
                placeholder='Select a Trigger'
                showSearch={true}
                filterOption={(input: any, option: any) =>
                  (option?.props?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {triggers.map((trigger) => (
                  <Option key={getRandomHash(5)} value={trigger}>
                    {trigger}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        {!disableCreate && (
          <Col span={6}>
            <Form.Item>
              <Button rtype='secondary' htmlType='submit'>
                Add Trigger
              </Button>
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  );
};
export function getRandomHash(length: number): string {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
export const TriggerForm = Form.create<Props>({
  name: "trigger_form",
  onFieldsChange(props, changedFields) {
  },
  onValuesChange(props, values, allValues) {
    if ((props as any).onChange) {
      (props as any).onChange(allValues);
    }
  },
})(
  _TriggerForm
);
