import React, { useEffect, useState } from "react";
import { Progress } from "antd";
import { AppStateType } from "../../models/app";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/state";


export const ResilienceIndicator: React.FC<any> = ({
}) => {

  const [realTimeResilience, setRealResilience] = useState<number>(0);
  const [executionResilience, setExecutionResilience] = useState<string | null>(null);

 const $activeAppState = useSelector<StoreState, AppStateType>(
   (state) => state.app.activeState
 );
  const $realTimeResilience = useSelector<StoreState, number>(
    (state) => state.realTime.resilience
  );

  const $executionResilience = useSelector<StoreState, string | null>(
    (state) => state.simulations.activeResilience
  );
  useEffect(() => {
    setRealResilience(
      Number(Number($realTimeResilience * 100).toFixed(2)).toString() as any
    );
  }, [$realTimeResilience]);


  useEffect(() => {
    setExecutionResilience($executionResilience);
  }, [$executionResilience]);

  return (
    <>
      {$activeAppState === "real-time" && (
        <>
          <div className='tab-resilience-live'>
            <div className='resilience-title-live'>Network Resilience</div>
            <Progress
              width={80}
              // type='circle'
              strokeColor={{
                "0%": "#108ee9",
                "100%": "#87d068",
              }}
              percent={Number(realTimeResilience)}
              format={(val) => `${val}%`}
            />
          </div>
        </>
      )}
      {$activeAppState === "executions" && executionResilience && (
        <div className='tab-resilience'>
          <div className='resilience-title'>Network Resilience</div>
          <Progress
            width={80}
            // type='circle'
            strokeColor={{
              "0%": "#108ee9",
              "100%": "#87d068",
            }}
            percent={Number(
              Number((executionResilience as any) * 100).toPrecision(4)
            )}
            format={(val) => `${Number(val)}%`}
          />
        </div>
      )}{" "}
    </>
  );

}