import { createSlice } from "@reduxjs/toolkit";

export const pollingSlice = createSlice({
  name: "polling",
  initialState: false,
  reducers: {
    init_polling: (state) => {
      state = true;
      return state;
    },
    stop_polling: (state) => {
      state = false;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { init_polling, stop_polling } = pollingSlice.actions;

export default pollingSlice.reducer;
