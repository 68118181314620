import React from "react";
import * as Ant from "antd";
import styled from "styled-components";
import { StyleVariables } from "../../styles/variables";
import Loader from "../Loader/loader";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;
export const InfoItem = styled.div`
  color: ${StyleVariables.primaryColor};
  font-size: 10px;
`;

const RealTimeLoader: React.FC<{ lastSync: string }> = ({ lastSync = "" }) => (
  <Container>
    <Loader />
    <InfoItem>
      <small>Last Sync</small>
    </InfoItem>
    <InfoItem>
      <small>{lastSync}</small>
    </InfoItem>
  </Container>
);

export default RealTimeLoader;
